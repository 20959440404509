<template>
  <div :style="[complete ? 'opacity: 0.5' : '']">
    <FrontendTextInput
      id="ht_text_input"
      :label="details.label"
      :placeholder="details.placeholder"
      :modelValue="payload.answer"
      @update:modelValue="newValue => updatePayload(newValue)"
      type="textarea"
      :rows="6"
      required
      :disabled="complete"
    >
      <template #description>
        <p class="frontend-text-input__description" v-html="details.helper"></p>
      </template>
    </FrontendTextInput>
  </div>
</template>

<script>
import FrontendTextInput from '~/Components/Frontend/Inputs/FrontendTextInput.vue';

export default {
  components: {
    FrontendTextInput,
  },
  emits: ['updatePayload'],
  props: {
    complete: Boolean,
    details: Object,
    payload: Object,
    errors: Object,
  },
  methods: {
    updatePayload(value) {
      this.$emit('updatePayload', 'answer', value);
      this.$emit('updatePayload', 'isValid', (value.length > 2));
    },
  },
};
</script>

<template>
  <MountedTeleport :to="teleportTo" :disabled="!teleportTo">
    <ul
      :class="{
      'frontend-tabs': true,
      'frontend-tabs--start': align === 'start',
      'frontend-tabs--end':   align === 'end',
      }"
    >
      <li
        v-for="tab in visibleTabs"
        :id="tab.name"
        :key="tab.name"
        :class="{'frontend-tabs__tab--active': activeTab.name === tab.name}"
        class="frontend-tabs__tab"
        @click="switchTab(tab)"
        role="button"
        tabindex="-1"
        >
        <i v-show="tab.icon" :class="tab.icon"></i> {{ tab.label }}
      </li>
      <li v-if="hasDefaultSlot" class="frontend-tabs__tab-extra">
        <slot></slot>
      </li>
    </ul>
  </MountedTeleport>
  <template v-for="tab in tabs" :key="tab.name">
    <div
      v-if="loadedTabs.includes(tab.name) || activeTab.name === tab.name"
      v-show="activeTab.name === tab.name"
      :class="`tab-pane-holder-${tab.name}`"
      >
        <slot :name="tab.name + '-content'"></slot>
    </div>
  </template>
</template>

<script>

import MountedTeleport from '~/Components/Frontend/MountedTeleport.vue';

export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    initialTab: {
      type: String,
      required: false,
    },
    urlParamName: {
      type: String,
      default: 'initialTab',
    },
    teleportTo: {
      type: String,
      required: false,
    },
    align: {
      type: String,
      default: 'center',
      validator(value) {
        return ['start', 'center', 'end'].includes(value);
      },
    },
  },
  components: {
    MountedTeleport,
  },
  data() {
    return {
      activeTab: this.getActiveTabByName(this.initialTab),
      loadedTabs: [this.initialTab],
    };
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
    visibleTabs() {
      return this.tabs.filter((tab) => (!('visible' in tab)) || tab.visible);
    },
  },
  methods: {
    switchTab(tab) {
      this.activeTab = tab;

      if (!this.loadedTabs.includes(tab.name)) {
        this.loadedTabs.push(tab.name);
      }

      const url = new URL(window.location);
      const { searchParams } = url;
      searchParams.set(this.urlParamName, this.activeTab.name);

      if (tab.code) {
        searchParams.set('type', tab.code);
      } else {
        searchParams.delete('type');
      }

      url.search = searchParams.toString();

      window.history.replaceState({ previousTabName: this.activeTab.name }, null, url);
    },
    getActiveTabByName(tabName) {
      if (tabName) {
        return this.tabs.find((tab) => tab.name === tabName);
      }
      return this.tabs[0];
    },
    showNav() {
      this.showNavMobile = true;
    },
  },
  created() {
    const self = this;
    window.addEventListener('popstate', (e) => {
      if (e.state && e.state.previousTabName) {
        self.activeTab = this.getActiveTabByName(e.state.previousTabName);
      }
    });
    // Replace current state so when we navigate back we don't cause problems
    window.history.replaceState({ previousTabName: this.activeTab.name }, null, window.location.href);
  },
};
</script>

<template>
    <div
      class="floorplan-viewer"
      :style="{
        'aspect-ratio': aspectRatio,
      }"
    >
      <FloorplanSVG
        v-if="floorplan?.id"
        :floorplan="floorplan"
        :selectedLocation="selectedLocation"
        ref="floorplanSVG"
        :lockSelected="false"
        :activePath="activePath"
        :isEditingLocation="false"
        @svgReady="zoomSoon"
        flashSelectedLocation
      />
    </div>
</template>

<script setup>

import { ref } from 'vue';
import axios from 'axios';
import { store } from '~/Frontend/store';

const props = defineProps({
  floorplanId: String,
  locationId: String,
  lockAspectRatio: Boolean,
});

const selectedLocation = ref(null);
const activePath = ref(null);

const floorplanSVG = ref(null);

const floorplan = ref(null);

const aspectRatio = ref('16 / 9');

axios.get(`/${store.eventEdition.slug}/floorplan/${props.floorplanId}`)
  .then((response) => {
    floorplan.value = response.data.floorplan;
    if (props.lockAspectRatio === false) {
      aspectRatio.value = `${floorplan.value.width} / ${floorplan.value.depth}`;
    }
  });

function zoomSoon() {
  if (props.locationId) {
    setTimeout(() => {
      const location = floorplan.value.locations.find((loc) => loc.id.toString() === props.locationId.toString());
      floorplanSVG.value.zoomToLocation(location);
      selectedLocation.value = location;
    }, 1000);
  }
}

</script>

export default {
  'assets.image.image': 'Imagen',
  'assets.image.name': 'Título de la imagen',
  'assets.video.title': 'Título del video',
  'assets.video.title.default': 'Video sin nombre',
  'assets.video.video': 'Video',
  'buttons.add': 'Añadir',
  'buttons.addMore': 'Añadir más',
  'buttons.back': 'Atrás',
  'buttons.cancel': 'Cancelar',
  'buttons.change': 'Cambiar',
  'buttons.contact': 'Contacto',
  'buttons.delete': 'Eliminar',
  'buttons.done': 'List',
  'buttons.edit': 'Editar',
  'buttons.help': 'Ayuda',
  'buttons.impersonateUser': 'Conéctame como',
  'buttons.manage': 'Administrar',
  'buttons.manageImages': 'Administra imágenes',
  'buttons.new': 'Nuevo',
  'buttons.next': 'Siguiente',
  'buttons.return': 'Regreso',
  'buttons.save': 'Guardar',
  'buttons.sendLink': 'Enviar enlace',
  'buttons.set': 'Set',
  'buttons.set_password': 'Establece la contraseña',
  'buttons.update': 'Actualizar',
  'buttons.uploadImage': 'Sube una imagen',
  'buttons.locked': 'Bloqueado',
  'buttons.write_with_ai': 'Escribe con IA',
  'buttons.recreate_with_ai': 'Recrear con IA',
  'buttons.notifications': 'Notificaciones',
  'buttons.run_search': 'Ejecutar',
  'buttons.show_more': 'Mostrar más',
  'buttons.show_less': 'Mostrar menos',
  'action.unfavourite': 'Eliminar de favoritos',
  'action.favourite': 'Favorito',
  'action.unhide': 'Mostrar',
  'action.hide': 'Esconder',
  'catalogue.catalogueEntry.description': 'Descripción',
  'catalogue.catalogueEntry.gallery': 'Imágenes',
  'catalogue.catalogueEntry.highlights': 'Aspectos destacados',
  'catalogue.catalogueEntry.image': 'Imagen',
  'catalogue.catalogueEntry.keywords': 'Palabras clave',
  'catalogue.catalogueEntry.links': 'Enlaces',
  'catalogue.catalogueEntry.moreOptions': 'Más opciones',
  'catalogue.catalogueEntry.name': 'Título',
  'catalogue_product_form.add_image_success': 'Ha añadido una imagen a :filename con éxito, será visible al actualizar la página.',
  'catalogue_product_form.delete': 'Eliminar',
  'catalogue_product_form.description': 'Discreción*:',
  'catalogue_product_form.edit': 'Editar',
  'catalogue_product_form.highlights.add': 'Añadir destaque',
  'catalogue_product_form.highlights.no_highlights': 'Sin destaques',
  'catalogue_product_form.highlights.placeholder': '',
  'catalogue_product_form.highlights.title': 'Destaques',
  'catalogue_product_form.image': 'Imagen del producto*',
  'catalogue_product_form.images': 'Imágenes',
  'catalogue_product_form.keywords': 'Palabras Clave',
  'catalogue_product_form.keywords_descriptions': "Separe varias palabras clave con una coma','",
  'catalogue_product_form.name': 'Nombre*:',
  'catalogue_product_form.order': 'Orden:',
  'catalogue_product_form.order_description': 'Orden ascendente (1 estará listado antes do 2).',
  'catalogue_product_form.recommended_image_size': 'Recomendamos 1280px por 720px (las imágenes se mostrarán en una proporción 16:9 ). Tamaño máximo del archivo 5Mb',
  'catalogue_product_form.remove_image_success': 'Ha añadido una imagen a :filename con éxito, será visible al actualizar la página.',
  'catalogue_product_form.save': 'Guardar',
  'catalogue_product_form.save_then_upload': 'Por favor guarde y luego haga clic en editar para subir las imágenes.',
  'catalogue_product_form.subtitle': 'Subtítulo*:',
  'catalogue_product_form.tagline': 'Eslogan*:',
  'catalogue_product_form.title.create': 'Crear {{ type }}',
  'catalogue_product_form.title.update': 'Actualizar {{ type }}',
  'catalogue_product_form.update': 'Actualize',
  'catalogue_product_form.update_photo': 'Actualizar la imagen',
  'catalogue_product_form.upload_photo': 'Sube imágenes',
  'catalogue_product_preview.buttons.view': 'Ver producto',
  'catalogue_product_preview.description': 'Descricíon',
  'catalogue_product_preview.highlights': 'Lo más destacado',
  'catalogue_product_preview.learn_more': 'Sepa más',
  'catalogue_product_preview.more': 'Más',
  'catalogue_product_preview.read_more': 'Más Información',
  'catalogue_product_preview.show_less': 'Mostrar menos',
  'catalogue_product_preview.view_product': 'Ver producto',
  'company.actions.business_card_dropped': 'Su tarjeta de visita fue compartida',
  'company.actions.drop_business_card': 'Enviar tarjeta de visita',
  'company.company_book_meeting_modal.cancel': 'Cancelar',
  'company.company_book_meeting_modal.cannot_meet': 'Usted no se puede encontrar con ningún contacto en esta empresa',
  'company.company_book_meeting_modal.meet': 'Reunirse',
  'company.company_book_meeting_modal.more': 'Más',
  'company.company_book_meeting_modal.next': 'Siguiente →',
  'company.company_book_meeting_modal.other_contacts': 'Otros contactos',
  'company.company_book_meeting_modal.recommended_contacts': 'Recomendados',
  'company.company_book_meeting_modal.select_contact': 'Seleccione un contacto para reunirse:',
  'company.company_book_meeting_modal.title': 'Programar reunión',
  'company.company_preview_modal.activities': 'Actividades y Intereses',
  'company.company_preview_modal.book_meeting': 'Concertar una reunión',
  'company.company_preview_modal.people': 'Equipo',
  'company.company_preview_modal.read_more': 'Leer más',
  'company.company_preview_modal.title': '{{ company_display_name }}',
  'company.company_preview_modal.view_people': 'Ver todos los contactos →',
  'company.company_preview_modal.view_profile_page': 'Ver la página del perfil',
  'company.drop_business_card.agreement': 'Estoy permitiendo que mi <strong> nombre, cargo y correo electrónico </strong> sean compartidos con {{ company_display_name }}',
  'company.drop_business_card.cancel': 'Cancelar',
  'company.drop_business_card.disclaimer': 'Yo comprendo que mi información será almacenada por {{ company_display_name }} y que necessito entrar en contato con {{ company_display_name }} para cambiar mis preferencias de comunicación.',
  'company.drop_business_card.header': 'Compartie su información de contato',
  'company.drop_business_card.share': 'Yo acepto',
  'company.drop_business_card.share_success.done': 'Hecho',
  'company.drop_business_card.share_success.message': 'Su nombre, cargo, correo electrónico y número del teléfono se compartieron con{{ company_display_name }}',
  'company.drop_business_card.share_success.title': 'La información fue compartida',
  'company_card.view_company': 'Visualizar Empresa',
  'concierge_modal.info_text': 'Contactar el equipo del concierge para questiones relaccionadas con reuniones y ayuda.',
  'concierge_modal.link_text': '¿Necesitas ayuda?',
  'concierge_modal.whatsapp_button': 'Chat en el WhatsApp',

  // Conference sessions
  'conference_sessions.ask_a_question.box_closing': 'Esta ventana cerrará automaticamente.',
  'conference_sessions.ask_a_question.buttons.ask': 'Envíe su questión',
  'conference_sessions.ask_a_question.buttons.cancel': 'Cancelar',
  'conference_sessions.ask_a_question.buttons.send': 'Enviar',
  'conference_sessions.ask_a_question.description': 'El moderador de esta sesión revisará tu questión',
  'conference_sessions.ask_a_question.header': 'Envíe su questión',
  'conference_sessions.ask_a_question.placeholder': 'Mi questión...',
  'conference_sessions.ask_a_question.question_submitted': 'Su questión ha sido sometida.',
  'conference_sessions.preview_session.speakers': 'Oradores',
  'conference_sessions.preview_session.title': 'Sesión de la conferencia',
  'conference_sessions.row.buttons.add_to_schedule': 'Añadir a la agenda',
  'conference_sessions.row.buttons.join_session': 'Unirse a la sesión',
  'conference_sessions.row.buttons.more': 'Más',
  'conference_sessions.row.buttons.preview_session': 'Vista previa',
  'conference_sessions.row.buttons.remove_from_schedule': 'Remover de la agenda',
  'conference_sessions.row.buttons.schedule_clash': 'Conflictos de agendas',
  'conference_sessions.row.buttons.booking_capacity_full': 'capacidad llena',
  'conference_sessions.row.buttons.watch_now': 'Ver ahora',
  'conference_sessions.row.session': 'Sesión',
  'conference_sessions.row.sponsored_by': 'Patrocinado por',
  'conference_sessions.row.starting_soon': 'Empieza pronto',

  'conversations.accepted_by': 'Invitación de reunión aceptada por {{ sender }}',
  'conversations.buttons.send_invite': 'Enviar invitación',
  'conversations.cancelled_by': 'Invitación de reunión cancelada por {{ sender }}',
  'conversations.chips.cancelled': 'Reunión Cancelada',
  'conversations.chips.colleague_added': 'Colega agregado',
  'conversations.chips.confirmed': 'Reunión confirmada',
  'conversations.chips.declined': 'Invitación rechazada',
  'conversations.chips.forwarded': 'Invitación reenviada',
  'conversations.chips.new_invite': 'Invitación a una reunión',
  'conversations.chips.updated': 'Reunión actualizada',
  'conversations.chips.withdrawn': 'Invitación retirada',
  'conversations.colleague_added': '{{ added_by }} añadiu {{ new_colleague }} a la reunión.',
  'conversations.declined_by': 'Invitación de reunión rechazada por {{ sender }}',
  'conversations.forwarded_to': 'Invitación de reunión pasada para {{ recipient }}',
  'conversations.invitation_from': 'Invitación de reunión de {{ sender }}',
  'conversations.view_meeting': 'Ver Reunión',
  'conversations.withdrawn_by': 'Invitación de reunión eliminada por {{ sender }}',
  'countdown.days': 'días',
  'countdown.hours': 'horas',
  'countdown.minutes': 'minutos',
  'countdown.seconds': 'segundos',
  'crm.communicationPreference.channels.email': 'Correos electrónicos de notificaión y recordatorio',
  'crm.communicationPreference.channels.phone': 'Soporta comunicación telefónica',
  'crm.communicationPreference.channels.sms': 'Mensajes de texto de notificación y recordatorio',
  'crm.communicationPreference.name': 'Preferencias',
  'crm.company.bio': 'Descripción de Compania',
  'crm.company.displayName': 'Nombre para mostrar',
  'crm.company.legalName': 'Nombre legal',
  'crm.company.logo': 'Logotipo de la empresa',
  'crm.company.officeLocation': 'Ubicación',
  'crm.company.officeLocations': 'Ubicaciones',
  'crm.company.socials': 'Redes Sociales',
  'crm.company.tagline': 'Breve introducción',
  'crm.company.websites': 'Sitios web',
  'crm.companyOfficeLocation.address': 'Dirección',
  'crm.companyOfficeLocation.country': 'País',
  'crm.contact.avatar': 'Foto de perfil',
  'crm.contact.bio': 'Sobre mi',
  'crm.contact.country': 'País',
  'crm.contact.email': 'Correo electrónico',
  'crm.contact.firstName': 'Nombre de pila',
  'crm.contact.fullName': 'Nombre',
  'crm.contact.jobTitle': 'Título del puesto',
  'crm.contact.lastName': 'Apellido',
  'crm.contact.lookingFor': 'Buscando ',
  'crm.contact.managedBy': 'Administradores de perfiles',
  'crm.contact.optouts': 'Exclusiones',
  'crm.contact.timezone': 'Zona horaria',
  'crm.contact.title': 'Título',
  'crm.link.type.website': 'Sitios web',
  'crm.link.url': 'Url',
  'crm.phone.types.mobile': 'Teléfono móvil',
  'crm.phone.types.office': 'Teléfono de oficina',
  'directory.default_search_name': '{{ date }} Resultados de búsqueda',
  'directory.search_last_run': 'Última ejecución: {{ date }}',
  'directory.saved_search_success': 'Búsqueda guardada con éxito.',
  'directory.updated_search_success': 'Búsqueda actualizada con éxito.',
  'directory.edit_search': 'Editar esta búsqueda',
  'directory.export_wishlist': 'Descargar',
  'directory.filters.apply': 'Aplicar',
  'directory.filters.close': 'Borrar filtros',
  'directory.filters.filters': 'Ordena y filtra',
  'directory.filters.open': 'Ordena y filtra',
  'directory.filters.primary.select_one_option': 'Seleccione <strong>una</strong> opción:',
  'directory.filters.reset': 'Reiniciar',
  'directory.noFavourites.message': 'Todavía no tienes favoritos.',
  'directory.noFavourites.title': 'No hay favoritos',
  'directory.noRecommendations.message': 'No hay resultados para mostrar.',
  'directory.noRecommendations.title': 'No hay resultados',
  'directory.noResults.message': 'No se han encontrado resultados,intente redefinir su búsqueda',
  'directory.noResults.title': 'Sin resultados',
  'directory.pagination.load_more': 'Ver más resultados',
  'directory.notification_frequency': 'Notificarme sobre nuevos resultados de búsqueda',
  'directory.save_search': 'Guardar búsqueda',
  'directory.search_name': 'Nombre de la búsqueda',
  'directory.saved_search_card_error': 'Algo salió mal, por favor refresca tu navegador',
  'directory.text_search': 'Buscar: {{ value }}',
  'directory.initial_search': 'Inicial: {{ value }}',
  'directory.noSavedSearches.message': 'No hay búsquedas guardadas. Comienza a <a href="?initialTab=search">buscar ...</a>',
  'drop_image.buttons.cancel': 'Cancelar',
  'drop_image.buttons.clear': 'Eliminar',
  'drop_image.buttons.remove': 'Quitar',
  'drop_image.buttons.submit': 'Enviar',
  'drop_image.preview_text': 'Vista previa del {{ image_name }}',
  'drop_image.text': 'Arraste la imagen o haga clic aquí',
  'event_editions.card.coming_soon': 'Próximamente',
  'event_editions.card.event_closed': 'Evento Finalizado',
  'event_editions.card.launch': 'Lanzar el evento',
  'event_editions.card.notify_me': 'Notifíqueme',
  'event_editions.card.reg_done': 'Se ha registrado para este evento',
  'event_editions.card.reg_open': 'Inscripción abierta',
  'event_editions.card.reg_soon': 'El registro empieza pronto',
  'event_editions.card.register': 'Regístrese ahora',
  'expanded_contact.buttons.accept_invite': 'Aceptar',
  'expanded_contact.buttons.book_meeting': 'Concertar una reunión',
  'expanded_contact.buttons.decline_invite': 'Rechazar',
  'expanded_contact.buttons.forward_to_colleague': 'Redirigir',
  'expanded_contact.buttons.withdraw_invite': 'Retirar invitación',
  'expanded_contact.buttons.match': 'Match',
  'expanded_contact.buttons.unmatch': 'Unmatch',
  'expanded_contact.buttons.block': 'Bloquear',
  'expanded_contact.buttons.unblock': 'Desbloquear',
  'expanded_contact.buttons.blocked': 'Bloqueado',
  'expanded_contact.buttons.matched': 'Coincidente',
  'expanded_contact.buttons.previously_blocked': 'Bloqueado anteriormente',
  'expanded_contact.buttons.previously_matched': 'Coincidencia anterior',
  'expanded_contact.meeting.addColleague.button': 'Añadir compañeros de equipo',
  'expanded_contact.meeting.addColleague.noColleagues': 'No hay compañeros de equipo disponibles',
  'expanded_contact.meeting.addColleague.subheader': 'Añadir compañeros de equipo',
  'expanded_contact.meeting.back': 'Volver a los detalles de reuniāo',
  'expanded_contact.meeting.cancel.button': 'Enviar Cancelación',
  'expanded_contact.meeting.cancel.subheader': 'No estoy disponible para participar en esta reunión',
  'expanded_contact.meeting.cancelledHeader': 'Reunión cancelada',
  'expanded_contact.meeting.contactNumber': 'Número de teléfono móvil: ',
  'expanded_contact.meeting.forwardToColleague.button': 'Seleccionar compañero de equipo',
  'expanded_contact.meeting.forwardToColleague.subheader': 'Encaminar reunión a un compañero de equipo',
  'expanded_contact.meeting.informationHeader': 'Información de la reunión',
  'expanded_contact.meeting.informationSubheader': 'Enviaremos un recordatorio por SMS, 10 minutos antes de la reunión.',
  'expanded_contact.meeting.inviteColleagues.button': 'Invitar a tus compañeros de equipo',
  'expanded_contact.meeting.inviteColleagues.subheader': 'Encaminar reunión a un compañero de equipo',
  'expanded_contact.meeting.missingContactNumber': 'Por favor agregue un número de teléfono móvil',
  'expanded_contact.meeting.participants': 'Participantes',
  'expanded_contact.meeting.reschedule.button': 'Reprogramar reunión',
  'expanded_contact.meeting.reschedule.subheader': 'Cambiar fecha, hora y ubicación de la reunión ',
  'expanded_contact.show.about.company.title': 'Informacion de la Empresa',
  'expanded_contact.show.about.contact.country': 'País',
  'expanded_contact.show.about.contact.looking_for': 'Buscando',
  'expanded_contact.show.about.contact.title': 'Sobre mí',
  'expanded_contact.show.colleagues': 'Compañeros del equipo',
  'expanded_contact.show.errors.cannot_load': 'No se pudo cargar la información de contacto.',
  'expanded_contact.show.other_contacts': 'Otros contactos de {{ company_display_name }}',
  'expanded_contact.show.respond': 'Contestar',
  'expanded_contact.show.send_invite': 'Enviar invitación',
  'expanded_contact.show.view_invite': 'Ver las invitaciones',
  'expanded_contact.show.view_meeting': 'Ver Reunión',
  'expanded_contact.show.view_profile': 'Ver perfil',

  // Exclusive events
  'exclusive_events.no_description': 'No hay descripción para este evento.',
  'exclusive_events.row.buttons.booking_capacity_full': 'Capacidad llena',
  'exclusive_events.row.buttons.booking_add_to_schedule': 'Añadir al calendario',
  'exclusive_events.row.buttons.booking_schedule_clash': 'Programar choque',
  'exclusive_events.row.buttons.booking_remove_from_schedule': 'Quitar del horario',

  'filter_modal.cancel': 'Cancelar',
  'filter_modal.save_update': 'Guardar y actualizar',
  'form.image.changeTip': '<a href=\'#\'>Haga clic para cambiar </a> o arrastra y suelta. <br/>',
  'form.image.maxSize': 'Tamaño máximo del archivo 5MB',
  'form.image.tip': '<a href=\'#\'>Haz clic para subir foto</a> o arrastra y suelta. <br/>',
  'form.image.unsupportedFileType': 'El tipo de archivo no es válido.  Use jpg, jpeg, png o webp',
  'form.image.recommended_size': 'Tamaño recomendado: {{recommended_size}}',
  'form.phone.placeholder': 'Enter your phone number',
  'form.video.tip': '<a href=\'#\'>Haz clic para subir vídeo</a> o arrastra y suelta. <br/>',
  'general.unknown_error': 'Lo sentimos, ha habido un error. Por favor, actualice la página en el navegador e intételo de nuevo',
  'inbox.header': 'Bandeja de Entrada',

  'hubs.header.stand': 'Stand: {{ stand }}',
  'hubs.companyProfile': 'Perfil de la empresa',
  'hubs.definitions.confirmedMeetings': 'Reuniones confirmadas',
  'hubs.definitions.receivedPendingInvites': 'Abrir invitaciones recebidas a reuniones',
  'hubs.definitions.sentPendingInvites': 'Abrir invitaciones enviadas a reuniones',
  'hubs.definitions.sentInvites': 'Invitaciones a reuniones eviadas (all statutes)',

  // hubs event profile
  'hubs.eventProfile.activity.favourited': 'Favoritos',
  'hubs.eventProfile.activity.hides': 'Esconder',
  'hubs.eventProfile.activity.marketplacePerformance': 'Rendimiento',
  'hubs.eventProfile.activity.marketplacePerformanceScore': 'Top {{ score }}%',
  'hubs.eventProfile.activity.marketplaceViews': 'Visualizaciones',
  'hubs.eventProfile.activity.noData': 'Datos insuficientes',
  'hubs.eventProfile.activity.performance': 'Desempeño del perfil',
  'hubs.eventProfile.activity.profileViews': 'Visitas a la página de perfil',
  'hubs.eventProfile.catalogueActive': 'Entradas activas',
  'hubs.eventProfile.catalogueDescription': 'Utilice los directorios de catálogos para promocionar sus productos y servicios.',
  'hubs.eventProfile.catalogueTitle': 'Entradas del catálogo',
  'hubs.eventProfile.countPlays': '{{ count }} Reproducciones',
  'hubs.eventProfile.description': 'Gestione su perfil y promocione su presencia en el evento.',
  'hubs.eventProfile.emptyList': 'No hay artículos en la lista.',
  'hubs.eventProfile.link': 'Perfil del evento',
  'hubs.eventProfile.marketplaceActive': 'Activo',
  'hubs.eventProfile.marketplaceDescription': 'Promocione sus productos y servicios a través de los directorios del marketplace.',
  'hubs.eventProfile.marketplaceTitle': 'Marketplace',
  'hubs.eventProfile.performanceScore': 'Top {{ score }}%',
  'hubs.eventProfile.title': 'Perfil del evento',
  'hubs.eventProfile.videos': 'Vídeos',
  'hubs.eventProfile.videosActive': 'Vídeos activos',
  'hubs.eventProfile.videosDescription': 'Gestione sus vídeos promocionales.',
  'hubs.eventProfile.videosTitle': 'Vídeos',
  'hubs.eventProfile.viewsPerDay': 'Vistas de perfil al día',

  // hubs insights
  'hubs.insights.activity': 'Desempeño del perfil de la empresa.',
  'hubs.insights.activity.favourited': 'Añadido a favoritos',
  'hubs.insights.activity.hides': 'Añadido a las pieles',
  'hubs.insights.activity.profilePreviews': 'Vista previa del perfil',
  'hubs.insights.activity.profileViews': 'Visitas de la página de perfil',
  'hubs.insights.description': 'Revise el rendimiento de su evento.',
  'hubs.insights.link': 'Perspectivas',
  'hubs.insights.meetings': 'Rendimiento de la reunión de empresa',
  'hubs.insights.title': 'Perspectivas',

  // hubs leads
  'hubs.leads.businessCardDropTable': 'Tarjetas de presentación olvidadas',
  'hubs.leads.businessCardDropTableDescription': 'Las siguientes personas han dejado su tarjeta de presentación en el perfil de tu empresa para hacerle seguimiento.',
  'hubs.leads.description': 'Acceda a sus clientes potenciales y tarjetas de presentación descartadas.',
  'hubs.leads.download': 'Descargar cliente potencial',
  'hubs.leads.downloadFormat': 'CSV / Excel',
  'hubs.leads.link': 'Leads',
  'hubs.leads.meetingsWith': 'Reuniones con {{ people }}',
  'hubs.leads.noDroppedBusinessCards': 'Ningún contacto ha dejado aún su tarjeta de presentación.',
  'hubs.leads.numberOfBusinessCardDrops': 'Tarjeta de presentación',
  'hubs.leads.receivedInvitesFrom': 'Recibí invitaciones de {{ people }}',
  'hubs.leads.sentInvitesTo': 'Invitaciones enviadas a {{ people }}',
  'hubs.leads.title': 'Gestión de clientes potenciales',

  // purchase items
  'hubs.purchase_items.available': 'Disponible',
  'hubs.purchase_items.emptyList': 'Artículo de compra no disponible.',
  'hubs.purchase_items.link': 'Actualizaciones y ofertas',
  'hubs.purchase_items.offers': 'Ofertas',
  'hubs.purchase_items.out_of_stock': 'Agotado',
  'hubs.purchase_items.product_not_available': 'Producto no disponible',
  'hubs.purchase_items.purchased': 'Comprado',
  'hubs.purchase_items.purchases': 'Compras',
  'hubs.purchase_items.someAvailable': '{{ num }} disponible',
  'hubs.purchases.purchased_at': 'Comprado en: ',
  'hubs.purchases.purchased_by': 'Comprado por: {{ contact }}',

  // hubs meetings
  'hubs.meetings.confirmedMeetings': 'Reuniones',
  'hubs.meetings.description': 'Revisa el desempeño de tu empresa.',
  'hubs.meetings.link': 'Reuniones',
  'hubs.meetings.MeetingsByDayTitle': 'Reuniones por día',
  'hubs.meetings.MeetingsByLocationTitle': 'Reuniones por ubicación',
  'hubs.meetings.performance': 'Desempeño de las reuniones',
  'hubs.meetings.performanceScore': 'Top {{score}}%',
  'hubs.meetings.receivedInvites': 'Invitaciones pendientes',
  'hubs.meetings.sentInvites': 'Invitaciones enviadas',
  'hubs.meetings.teamHasMeetings': 'Reuniones confirmadas',
  'hubs.meetings.teamHasPending': 'Reuniones pendientes',
  'hubs.meetings.teamHasSent': 'Invitaciones enviadas',
  'hubs.meetings.teamTitle': 'Reuniones de equipo',
  'hubs.meetings.title': 'Reuniones',

  'hubs.stats.average': 'Average',
  'hubs.stats.belowAverage': 'Low',

  'hubs.meetings.teamSchedules.unavailable': 'Reservado como No Disponible',
  'hubs.meetings.teamSchedules': 'Horarios del equipo',
  'hubs.meetings.teamSchedules.instructions': 'Seleccione un día para ver el horario de un contacto para un período determinado.',
  'hubs.meetings.teamSchedules.bookings.empty': '{{sharer}} no tiene reservas durante este período.',
  'hubs.meetings.teamSchedules.combinedBookings.empty': 'No hay reservas durante este período.',

  // hubs team
  'hubs.team.lastLogin': 'Último acceso',
  'hubs.team.activityChartSubtitle': 'últimos 60 días',
  'hubs.team.activityChartTitle': 'Actividad del equipo -',
  'hubs.team.badgeTable': 'Equipo del evento',
  'hubs.team.profilesHidden': 'Perfiles ocultos',
  'hubs.team.contactUpdatedPlatformProfile': 'Perfiles actualizados',
  'hubs.team.description': 'Monitorear la actividad y presencia del equipo.',
  'hubs.team.link': 'Equipo',
  'hubs.team.noOtherBadges': 'Ninguno de sus colegas está registrado en el evento',
  'hubs.team.profilesActivated': 'Perfiles activados',
  'hubs.team.title': 'Título del equipo ',

  'inbox.invites.buttons.accept': 'Aceptar',
  'inbox.invites.buttons.askQuestion': 'Enviar mensaje',
  'inbox.invites.buttons.decline': 'Rechazar',
  'inbox.invites.buttons.forward': 'Pasar',
  'inbox.invites.buttons.moreOptions': 'Más opciones ...',
  'inbox.invites.buttons.next_invite': 'Invitación siguiente',
  'inbox.invites.buttons.read_more': 'más',
  'inbox.invites.buttons.respond': 'Responder',
  'inbox.invites.buttons.show_less': 'mostrar menos',
  'inbox.invites.buttons.view': 'Ver',
  'inbox.invites.buttons.viewProfile': 'Ver perfil',
  'inbox.invites.buttons.withdraw': 'Eliminar',
  'inbox.invites.declinedByActiveUser': 'Has rechazado esta invitation {{ declined_time }}',
  'inbox.invites.declinedByRecipient': '{{ declined_by }} Rechazó esta invitación {{ declined_time }}',
  'inbox.invites.expiry.expired': 'Caducado',
  'inbox.invites.expiry.expires': 'Caduca',
  'inbox.invites.expiry.expiresSoon': 'Tienes invitaciones a reuniones que expiran pronto.',
  'inbox.invites.expiry.whenExpired': 'Tienes invitaciones a reuniones expiradas.',
  'inbox.invites.findPeople': 'Busca',
  'inbox.invites.forwardedByActiveUser': 'Has reenviado esta invitación a {{ forwardee }}',
  'inbox.invites.forwardedByColleague': 'Pasado para ti por {{ forwarded_by }}',
  'inbox.invites.forwardedByRecipient': 'Pasado para{{ forwardee }} por {{ forwarded_by }}',
  'inbox.invites.info.sent_message_concierge': 'El equipo de conserjería de reuniones se pondrá en contacto con este usuario si no responde pronto.',
  'inbox.invites.noResults.message': '<a href="{{ findPeopleUrl }}">Busca</a> para nuevas oportunidades de reunión.',
  'inbox.invites.noResults.title': 'No tiene invitaciones de reunión',
  'inbox.invites.sort.newest': 'Más reciente',
  'inbox.invites.sort.oldest': 'Más antiguo',
  'inbox.invites.sort.placeholder': 'Ordenar',
  'inbox.invites.statuses.accepted': 'Aceptado',
  'inbox.invites.statuses.declined': 'Rechazado',
  'inbox.invites.statuses.expired': 'Caducado',
  'inbox.invites.statuses.forwarded': 'Pasado',
  'inbox.invites.statuses.pending': 'Esperando respuesta',
  'inbox.invites.statuses.sent': 'Enviado',
  'inbox.invites.statuses.unread': 'Nuevo mensaje',
  'inbox.invites.statuses.withdrawn': 'Eliminado',
  'inbox.invites.statusFilters.accepted': 'Aceptado',
  'inbox.invites.statusFilters.declined': 'Rechazado',
  'inbox.invites.statusFilters.expired': 'Caducado',
  'inbox.invites.statusFilters.forwarded': 'Pasado',
  'inbox.invites.statusFilters.pending': 'Acçāo necessária',
  'inbox.invites.statusFilters.sent': 'Enviado',
  'inbox.invites.statusFilters.withdrawn': 'Eliminado',
  'inbox.invites.whenReceived': 'Recibido',
  'inbox.invites.whenSent': 'Enviado',
  'inbox.invites.withdrawnByActiveUser': 'Retiraste esta invitación {{ withdrawn_time }}',
  'inbox.invites.withdrawnBySender': '{{ withdrawn_by }} withdrew this invite {{ withdrawn_time }}',
  'inbox.loading': 'Cargando...',
  'inbox.meetings.buttons.addColleagues': 'Invita a tus colegas',
  'inbox.meetings.buttons.cancel': 'Cancela la reunión',
  'inbox.meetings.buttons.moreOptions': 'Más opciones ...',
  'inbox.meetings.buttons.rescheduleMeeting': 'Reprogramar la reunión',
  'inbox.meetings.cancelledBy': '{{ cancelled_by }} Canceló esta invitación {{ cancel_time }}',
  'inbox.meetings.cancelledByActiveUser': 'Has cancelado esta reunión meeting {{ cancel_time }}',
  'inbox.meetings.filters.allDays': 'Todos',
  'inbox.meetings.filters.showCancelled': 'Enseñar reuniónes canceladas',
  'inbox.meetings.noResults.message': '<a href="{{ findPeopleUrl }}">Busca</a> para nuevas oportunidades de reunión.',
  'inbox.meetings.noResults.title': 'Todavía no tienes reuniones',
  'inbox.meetings.rate': 'Valorar reunión',
  'inbox.meetings.statuses.cancelled': 'Cancelado',
  'inbox.meetings.statuses.confirmed': 'Reunión confirmada',

  'inbox.meetings.view': 'Información',
  'inbox.messages.info.moreMessagesInThread': 'Hay más mensajes en esta conversación.',
  'inbox.messages.info.newUnreadMessage': 'Nuevo mensaje de chat. <a href="{{url}}">Responder</a>',
  'inbox.messages.meetingInformation': 'Información de la reunión',
  'inbox.messages.noResults.message': 'No tienes mensajes',
  'inbox.messages.noResults.title': 'No hay mensajes',
  'inbox.messages.statusFilters.all': 'Todos',
  'inbox.messages.statusFilters.unread': 'No leído',

  'inbox.messages.sort.newest': 'Más reciente',
  'inbox.messages.sort.oldest': 'Más antiguo',

  'inbox.messages.whenReceived': 'Recibido',
  'inbox.messages.whenSent': 'Enviado',
  'inbox.nextPage': 'Página siguiente',
  'inbox.previousPage': 'Página anterior',
  'inbox.relationshipLabels.from': 'De:',
  'inbox.relationshipLabels.to': 'Para:',
  'inbox.relationshipLabels.with': 'Con:',
  'inbox.subtitle.meetings': 'Reuniones confirmadas',
  'inbox.subtitle.messages': 'Mensajes',
  'inbox.subtitle.null': ' ',
  'inbox.subtitle.receivedInvites': 'Invitaciones recibidas',
  'inbox.subtitle.sentInvites': 'Invitaciónes enviadas',
  'meeting.add_colleagues.buttons.add': 'Añadir compañero de equipo',
  'meeting.add_colleagues.buttons.cancel': 'Cancelar',
  'meeting.add_colleagues.buttons.done': 'Hecho',
  'meeting.add_colleagues.buttons.invite_more': 'Invitar a más',
  'meeting.add_colleagues.header': 'Añadir compañeros de equipo',
  'meeting.add_colleagues.no_colleagues': 'No hay compañeros de equipo disponibles para agregar',
  'meeting.add_colleagues.subheader': 'Añadir compañeros de equipo a tu reunión con <strong>{{display_name}}, {{job_title}}</strong> de <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting.add_colleagues.success.message_one': 'Compañero de equipo se ha añadido a esta reunión con éxito.',
  'meeting.add_colleagues.success.message_other': 'Compañeros de equipo se han añadido a esta reunión con éxito.',
  'meeting.add_colleagues.success.title_one': 'Compañero de equipo añadido',
  'meeting.add_colleagues.success.title_other': 'Compañeros de equipo añadidos',
  'meeting.addColleague.button': 'Añadir compañeros de equipo',
  'meeting.addToCalendar': 'Añadir al calendario',
  'meeting.attendees': 'Tu y {{ displayName }}',
  'meeting.attendeesCount': '{{count}} Gente',
  'meeting.attendeesTitle': 'Participantes',
  'meeting.back_to_meeting': 'Volver a la reunión',
  'meeting.cancel.button': 'Enviar Cancelación',
  'meeting.cancel.cannotAttend': 'Ya no me encuentro disponible para atender esta reunión',
  'meeting.cancel.keep': 'Mantener reunión',
  'meeting.cancel.messageLabel': 'Nota',
  'meeting.cancel.messagePlaceholder': 'Desafortunadamente tengo que cancelar esta reunion porque ...',
  'meeting.cancel.onlyOrganiserCanCancel': 'Solo el organizador puede cancelar la reunión',
  'meeting.cancel.onlyOrganiserCanReschedule': 'Solo el organizador puede reorganizar la reunión',
  'meeting.cancel.reasonLabel': 'Motivos de cancelación',
  'meeting.cancel.reasonPlaceholder': 'Seleccionar opción...',
  'meeting.cancel.subheader': 'Cancelar su reunión programada con <strong> {{ displayName }}, {{ jobTitle }}</strong> de <a href="{{ companyUrl }}"> {{ companyDisplayName }} </a>.',
  'meeting.cancel.title': 'Cancelar Reunión',
  'meeting.cancellation_success.message': 'Usted canceló esta reunión con <strong>{{display_name}}, {{job_title}}</strong> de <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting.cancellation_success.title': 'Invitación rechazada',
  'meeting.cancelledBy': 'Esta reunión ha sido cancelada por{{ cancelledBy_display_name }} {{cancelled_at_time}}',
  'meeting.close': 'Cerrar',
  'meeting.countdown_minutes': 'minutos, ',
  'meeting.countdown_seconds': 'segundos',
  'meeting.errors.cannot_load': 'Reunión no ha cargado.',
  'meeting.finished': 'Reunión ha terminado.',
  'meeting.forward.button': 'Seleccionar compañero de equipo',
  'meeting.forward.subheader': 'Pasar el reunión para un compañero de equipo',
  'meeting.forward.title': 'Pasar al compañero de equipo',
  'meeting.in_progress': 'Reunión en curso.',
  'meeting.join_now': 'Entrar ahora',
  'meeting.meeting_confirmed.invite_colleagues': 'Invitar a compañeros de equipo',
  'meeting.meeting_confirmed.message': 'Su compañero de reunión ha sido notificado.',
  'meeting.meeting_confirmed.next_invite': 'Invitación siguiente',
  'meeting.meeting_confirmed.title': 'Reunión confirmada',
  'meeting.meeting_confirmed.view_meeting': 'Ver detalles',
  'meeting.meeting_starts_in_countdown': 'Reunión empieza en',
  'meeting.meetingCancelled': 'Reunión cancelada',
  'meeting.meetingConfirmed': 'Confirmado por todas las partes.',
  'meeting.mobileNumberLabel': 'Número de teléfono móvil:',
  'meeting.panelTitle.cancelled': 'Cancelar reunión',
  'meeting.panelTitle.information': 'Información de la reunión',
  'meeting.phoneNumberMissing': '<a href="{{ updatePhoneUrl }}">Por favor añadir un número de teléfono móvil</a>',
  'meeting.rate_meeting.back_to_meeting': 'Volver a el reunión',
  'meeting.rate_meeting.buttons.cancel': 'Cancelar',
  'meeting.rate_meeting.buttons.done': 'Hecho',
  'meeting.rate_meeting.buttons.next': 'Siguiente',
  'meeting.rate_meeting.buttons.rate': 'Valorar',
  'meeting.rate_meeting.buttons.save': 'Guardar Comentarios',
  'meeting.rate_meeting.errors.already_rated': 'Esta reunión ha sido valorada',
  'meeting.rate_meeting.fields.feedback.label': 'Comentários sobre la reunión (opcional)',
  'meeting.rate_meeting.fields.meeting_did_not_happen.label': 'La reunión no sucedió',
  'meeting.rate_meeting.fields.rating.label': 'Valorar a sua reunión',
  'meeting.rate_meeting.header': 'Valorar su reunión',
  'meeting.rate_meeting.subheader': '¿Cómo fue su reunión con <strong>{{display_name}}, {{job_title}}</strong> de {{company_display_name}}?',
  'meeting.rate_meeting.success.title': 'Valoración de reunión sometida',
  'meeting.reminder': 'Enviaremos un recordatorio por SMS, 10 minutos antes de la reunión.',
  'meeting.reschedule.back_to_meeting': 'Volver a el reunión',
  'meeting.reschedule.button': 'Reagendar reunión',
  'meeting.reschedule.subheader': 'Cambiar fecha, hora y ubicación del reunión',
  'meeting.reschedule.title': 'Reagendar reunión',
  'meeting.source_types.auto_accepted': 'Aceptar automáticamente',
  'meeting.source_types.ez_match': 'Ez Match',
  'meeting.source_types.concierge_booked': 'Concierge',
  'meeting.source_types.walk_up': 'Reunión planificada en el lugar',
  'meeting.stand': 'Stand {{ stand_number }}',
  'meeting.startingTime': 'Reunión empieza en{{ time_until_meeting }}',
  'meeting.statuses.cancelled': 'Cancelar',
  'meeting.updatePhone': 'Actualizar número',
  'meeting_invite.back_to_conversation': 'Volver a la conversación',
  'meeting_invite.decline.buttons.cancel': 'Cancelar',
  'meeting_invite.decline.buttons.decline': 'Rechazar invitación de reunión',
  'meeting_invite.decline.buttons.done': 'Hecho',
  'meeting_invite.decline.errors.cannot_load': 'Invitación no ha cargado.',
  'meeting_invite.decline.header': 'Rechazar invitación de reunión',
  'meeting_invite.decline.inputs.message.label': 'Añadir un mensaje',
  'meeting_invite.decline.inputs.reason.label': 'Motivos de rechazo',
  'meeting_invite.decline.inputs.reason.placeholder': 'Seleccionar motivo',
  'meeting_invite.decline.subheader': 'Rechazar invitación de reunión de <strong>{{display_name}}, {{job_title}}</strong> de <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.decline.success.message': 'Usted rechazó una invitación de reunión con éxito de <strong>{{display_name}}, {{job_title}}</strong> de <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.decline.success.title': 'Invitación rechazada',
  'meeting_invite.forward_invite.buttons.cancel': 'Mantener la solicitud',
  'meeting_invite.forward_invite.buttons.done': 'Hecho',
  'meeting_invite.forward_invite.buttons.forward': 'Pasar invitación',
  'meeting_invite.forward_invite.header': 'Pasar invitación para un compañero de equipo',
  'meeting_invite.forward_invite.inputs.forward_to_colleague.label': 'Incluir un mensaje para mi compañero de equipo:',
  'meeting_invite.forward_invite.inputs.forward_to_colleague.placeholder': 'He recibido una invitación de reunión que es más relevante para ti...',
  'meeting_invite.forward_invite.subheader': 'Pasar una invitación de <strong>{{display_name}}, {{job_title}}</strong> de <a href="{{company_url}}">{{company_display_name}}</a> para un compañero de equipo',
  'meeting_invite.forward_invite.success.message': 'Hemos enviado esta invitación de reunión con éxito para su compañero de equipo',
  'meeting_invite.forward_invite.success.title': 'Invitación de reunión enviada a un compañero de equipo',
  'meeting_invite.opt_in_meetings.accept': 'Si, quiero participar en el servicio de networking',
  'meeting_invite.opt_in_meetings.decline': 'No, no quiero participar en el servicio de networking',
  'meeting_invite.opt_in_meetings.opt_in_meetings_text': 'Únete al servicio de Networking para encontrar contactos, programar reuniones y recibir invitaciones de otros usuários del evento.',
  'meeting_invite.opt_in_meetings.previously_requested_opt_out': 'Usted pidió para no utilizar el servicio de networking.',
  'meeting_invite.opt_in_meetings.title': 'Participacíon en el servicio de Networking',
  'meeting_invite.pending_invites.limit_reached.body': 'Puedes enviar más invitaciones una vez que se hayan aceptado sus invitaciones actuales.',
  'meeting_invite.pending_invites.limit_reached.title': 'Ha alcanzado el límite de invitaciones utilizadas',
  'meeting_invite.pending_invites.remaining': '<i class="fa-solid fa-circle-info"></i> Ha utilizado <strong>{{ invite_spent }} de las {{ invite_credits }}</strong> invitaciones de reunión pendientes.',
  'meeting_invite.send_invite.buttons.cancel': 'Cancelar',
  'meeting_invite.send_invite.buttons.done': 'Hecho',
  'meeting_invite.send_invite.buttons.send': 'Enviar invitación',
  'meeting_invite.send_invite.buttons.view_sent_invites': 'Invitaciones enviadas',
  'meeting_invite.send_invite.errors.cannot_send_invite': 'No fue posible enviar la invitación a este contacto.',
  'meeting_invite.send_invite.header': 'Crear una invitación de reuniāo',
  'meeting_invite.send_invite.message_placeholder': 'Hola, me gustaría reunirme para discutir...',
  'meeting_invite.send_invite.sub_text': 'Tu compañero de reunión confirmará la fecha y hora de tu reunión.',
  'meeting_invite.send_invite.success.message': 'Tu compañero de reunión revisará tu invitación y confirmará la fecha, hora y ubicación en función de su disponibilidad.',
  'meeting_invite.send_invite.success.title': 'Invitación enviada',
  'meeting_invite.send_invite.success.update_availability': 'Actualizar mi disponibilidad',
  'meeting_invite.withdraw.buttons.cancel': 'Cancelar',
  'meeting_invite.withdraw.buttons.done': 'Hecho',
  'meeting_invite.withdraw.buttons.withdraw': 'Borrar invitación',
  'meeting_invite.withdraw.errors.cannot_load': 'Invitación no ha cargado.',
  'meeting_invite.withdraw.header': 'Eliminar invitación',
  'meeting_invite.withdraw.inputs.message.label': 'Añadir un mensaje',
  'meeting_invite.withdraw.inputs.reason.label': 'Motivo de anulación de la invitación',
  'meeting_invite.withdraw.inputs.reason.placeholder': 'Seleccionar motivo',
  'meeting_invite.withdraw.subheader': 'Eliminar invitación de reunión de <strong>{{display_name}}, {{job_title}}</strong> de <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.withdraw.success.message': 'has eliminado con éxito la invitación de reunión de <strong>{{display_name}}, {{job_title}}</strong> de <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.withdraw.success.title': 'Eliminar invitación',
  'meeting_scheduler.acceptInvitation': 'Acepta la invitación',
  'meeting_scheduler.buttons.back': 'Atrás',
  'meeting_scheduler.buttons.done': 'Hecho',
  'meeting_scheduler.buttons.next': 'Próxima',
  'meeting_scheduler.errors.invite_already_actioned': 'Esta invitación fue activada. ',
  'meeting_scheduler.errors.invite_already_actioned_link': '<a href="#/contacts/{{ invite_counterpart_contact_id }}/conversación">View</a>',
  'meeting_scheduler.errors.no_available_slots': 'No hay espacios para reuniones disponibles.',
  'meeting_scheduler.floorplanClickForFull': 'Plano de plants a página complete',
  'meeting_scheduler.meetingUpdatedTitle': 'Reunión actualizada',
  'meeting_scheduler.rescheduleMeeting': 'Reprogramar la reunión',
  'meeting_scheduler.standLocation': '{{ companyName }} stand {{ standNumberFull }}',
  'meeting_scheduler.step.date': 'Fecha',
  'meeting_scheduler.step.location': 'Ubicación',
  'meeting_scheduler.step.time': 'Hora',
  'meeting_scheduler.unavailable': 'No disponible',
  'multi_links.add_link': 'Añadir enlance',
  'multi_links.address': 'exemplo. https://www.{address}.com/...',
  'multi_links.error': 'Por favor comprueba que el tipo y el url estan definidos.',
  'multi_links.please_select': 'Por favor seleccione',
  'multi_links.title': 'Enlances',
  'multi_links.type': 'Tipo',
  'mux_video_upload.buttons.cancel': 'Cancelar',
  'mux_video_upload.buttons.delete': 'Borrar',
  'mux_video_upload.buttons.rename': 'Renombrar',
  'mux_video_upload.buttons.save': 'Guardar',
  'mux_video_upload.buttons.upload': 'Subir',
  'mux_video_upload.error_text': 'Ha habido un error al cargar el vídeo, por favor intente de nuevo',
  'mux_video_upload.input.label': 'Título',
  'mux_video_upload.success_text': 'Su archivo se ha cargado con éxito y estará listo pronto',
  'mux_video_upload.video_status.created': 'Nuevo',
  'mux_video_upload.video_status.deleted': 'Eliminado',
  'mux_video_upload.video_status.errored': 'Erroizado',
  'mux_video_upload.video_status.new_video': 'Seleccione y suba el archivo',
  'mux_video_upload.video_status.processing': 'Procesando',
  'mux_video_upload.video_status.ready': 'Listo',
  'mux_video_upload.video_status.removed': 'Eliminado',
  'mux_video_upload.video_status.upload-cancelled': 'Se ha cancelado la carga',
  'mux_video_upload.video_status.upload-failed': 'No se pudo cargar',
  'mux_video_upload.video_status.upload_failed': 'Cargamento incompleto, por favor elimine y tiente novamente',
  'mux_video_upload.video_status.uploading': 'Cargando, por favor no cierre esta página ya que puede detener su carga',
  'mux_video_upload.video_status.waiting': 'Esperando',
  'my_schedule.bookings.source_types.auto_accepted': 'Aceptados automaticamente',
  'my_schedule.bookings.source_types.ez_match': 'Ez Match',
  'my_schedule.bookings.source_types.concierge_booked': 'Concierge',
  'my_schedule.bookings.source_types.walk_up': 'Reuniónes hechos en el local',
  'my_schedule.bookings.table_number': '(mesa {{ number }})',
  'my_schedule.other_bookings.buttons.add': 'Añadir',
  'my_schedule.other_bookings.buttons.addOther': 'Otro',
  'my_schedule.other_bookings.buttons.cancel': 'Cancelar',
  'my_schedule.other_bookings.buttons.delete': 'Borrar',
  'my_schedule.other_bookings.buttons.no_undos': 'Esta acción no se puede deschacer',
  'my_schedule.other_bookings.buttons.update': 'Actualizar',
  'my_schedule.other_bookings.fields.description.label': 'Detalles',
  'my_schedule.other_bookings.fields.description.placeholder': 'Almuerzo con el proveedor',
  'my_schedule.other_bookings.fields.end_datetime.label': 'Fin',
  'my_schedule.other_bookings.fields.start_datetime.label': 'Inicio',
  'my_schedule.other_bookings.header_add': 'Añadir',
  'my_schedule.other_bookings.header_update': 'Actualizar',
  'my_schedule.other_bookings.sub_text': "Tu estado aparecerá como 'no disponible' durante este tiempo.",
  'needHelp.dataPrivacy.body': 'Los clientes potenciales recopliados están sujetos a las condiciones de uso y la política de privacidad de datos.',
  'needHelp.dataPrivacy.header': 'Privacidad de datos',
  'needHelp.dataPrivacy.link': 'Política privada',
  'needHelp.body': 'Póngase en contacto con el equipo de conserjería del evento.',
  'needHelp.header': '¿Necesitas ayuda?"',
  'needHelp.link': 'Contacta con nosotros',
  'profile.account_settings.email': 'Correo electrónico',
  'profile.account_settings.first_name': 'Nombre',
  'profile.account_settings.last_name': 'Apellido',
  'profile.account_settings.salutation': 'Título/Tratamiento',
  'profile.account_settings.title': 'Definiciones de cuenta',
  'profile.buttons.cancel': 'Cancelar',
  'profile.buttons.edit': 'Editar',
  'profile.buttons.save': 'Guardar',
  'profile.communication_preferences.email': 'Notificaciones y recordatorios por correo electrónico',
  'profile.communication_preferences.phone': 'Comunicación y soporte telefónico',
  'profile.communication_preferences.sms': 'Notificaciones y recordatorios por SMS',
  'profile.communication_preferences.something_went_wrong': 'Oops - algo salió mal, por favor inténtelo de nuevo más tarde',
  'profile.communication_preferences.subheader': 'Administrar sus preferencias del programa de reuniones',
  'profile.communication_preferences.title': 'Preferencias de comunicación',
  'profile.contact_details.add_another_number': 'Añadir otro número de teléfono',
  'profile.contact_details.add_number': 'Añadir número de teléfono',
  'profile.contact_details.input_label': '{{ phone_type }} número',
  'profile.contact_details.mobile': 'Número de teléfono móvil',
  'profile.contact_details.mobile_number': 'Número de teléfono móvil',
  'profile.contact_details.mobile_placeholder': 'Añadir número de teléfono',
  'profile.contact_details.office': 'Número de oficina',
  'profile.contact_details.office_number': 'Número de oficina',
  'profile.contact_details.phone_number': 'Número de teléfono',
  'profile.contact_details.phone_number_missing': 'No tienes números de teléfono.',
  'profile.contact_details.phone_placeholder': 'Añadir número de teléfono',
  'profile.contact_details.please_add_a_number': 'Por favor añade número de teléfono',
  'profile.contact_details.select_country_code': 'Seleccionar código de país',
  'profile.contact_details.title': 'Información de contacto',
  'profile.demographics.subheader': 'Introduzca esta información para ayudar a otros usuarios a encontrarlo en la búsqueda',
  'profile.demographics.title': 'Búsqueda de Información',
  'profile.event_features.something_went_wrong': 'Por favor, actualice la página e inténtelo de nuevo',
  'profile.event_features.subheader': 'Características específicas del evento.',
  'profile.event_features.title': 'Características del evento',
  'profile.event_features.type_state_auto_accept_meeting_invite_false': 'No aceptar automáticamente invitaciones de reunión.',
  'profile.event_features.type_state_auto_accept_meeting_invite_true': 'Aceptar automáticamente TODAS las invitaciones de reunión.',
  'profile.forms.catalogueEntry.create.modalTitle': 'Añade nuevo {{ name, lowercase }}',
  'profile.forms.catalogueEntry.edit.modalTitle': 'Editar {{ name, lowercase }}',
  'profile.forms.image.create.heading': 'Añade una nueva imagen a tu catálogo de productos.',
  'profile.forms.image.create.modalTitle': 'Añadir imagen',
  'profile.forms.image.edit.heading': 'Cambia el título de las imágenes\'s',
  'profile.forms.image.edit.modalTitle': 'Edita la imagen',
  'profile.forms.image.progressMessage': 'Tu images se está cargando, espere, por favor',
  'profile.forms.video.create.modalTitle': 'Sube el vídeo',
  'profile.forms.video.edit.modalTitle': 'Edita el vídeo',
  'profile.forms.video.progressMessage': 'Se está cargando tu vídeo, espere, for favor',
  'profile.groups.aboutMe.heading': 'Sobre mí',
  'profile.groups.accountAccess.heading': 'Acceso a la cuenta',
  'profile.groups.accountAccess.subheading': 'Estos usuarios pueden administrar tu perfil, chat y actividad de reuniones.',
  'profile.groups.catalogue.heading': '{{ plural }}',
  'profile.groups.companyDemographics.heading': 'Otra información',
  'profile.groups.companyInformation.heading': 'Información de la empresa',
  'profile.groups.companyInformation.subheading': 'Administra el perfil de tu empresa',
  'profile.groups.contactInformation.heading': 'Información de contacto',
  'profile.groups.eventOptouts.heading': 'Evento Exclusiones',
  'profile.groups.password.heading': 'Contraseña de cuenta',
  'profile.groups.password.lastChanged': 'Modificado por última vez <b> {{ lastChangedAt }} </b>',
  'profile.groups.password.subheading': 'La contraseña debe tener al menos 8 caracteres e incluir una letra mayùscula, un número y un carácter especial.',
  'profile.groups.searchInformation.heading': 'Otra información',
  'profile.groups.team.heading': 'Miembros del equipo <small>({{ count }})</small>',
  'profile.groups.timezone.heading': 'Zona horaria',
  'profile.groups.timezone.subheading': 'Consulta las fechas y horas de los events en tu zone horaria preferida . <b>(Eventos virtuales)</b>.',
  'profile.informationSharingNotification': 'Su información de contact <b>no</b> se muestra en tu perfil público.',
  'profile.location_language.country': 'País',
  'profile.location_language.timezone': 'zona horaria',
  'profile.location_language.title': 'Ubicación e idioma',
  'profile.modals.previewCompanyCard.modalTitle': 'Otenga una vista previa de su tarjeta de empresa',
  'profile.modals.previewContactCard.': 'Vista previa de tu tarjeta',
  'profile.modals.previewContactCard.modalTitle': 'Vista previa de tu tarjeta',
  'profile.optouts.something_went_wrong': 'Por favor, actualice la página e inténtelo de nuevo',
  'profile.optouts.subheader': 'Al seleccionar estas opciones, ocultará su perfil y/o rechazar todas las reuniones y otras citas.',
  'profile.optouts.title': 'Dejar de participar',
  'profile.optouts.type_state_findable_false': 'NO estoy visible en el portal.',
  'profile.optouts.type_state_findable_true': 'Estoy visible en el portal.',
  'profile.optouts.type_state_meetable_false': 'NO estoy disponible para reuniones.',
  'profile.optouts.type_state_meetable_true': 'Estoy disponible para reuniones.',
  'profile.pages.catalogue.header': 'Marketplace',
  'profile.pages.companyProfile.header': 'Perfil de la empresa',
  'profile.pages.companyProfile.previewButton': 'Vista previa del perfil de la empresa',
  'profile.pages.manageContent.header': 'Administra el contenido',
  'profile.pages.manageContent.warning': 'La configuración de eventos puede limitar la visualización de algunos contenidos.',
  'profile.pages.myAccount.action': 'Evento abierto',
  'profile.pages.myAccount.eventAndBadges': 'Mis eventos y insignias',
  'profile.pages.myAccount.header': 'Página de inicio de mi cuenta',
  'profile.pages.myAccount.welcome': 'Hola {{ firstName }}, Bienvenido a tu cuenta.',
  'profile.pages.notificationSettings.header': 'Configuración de notificaciones',
  'profile.pages.passwordSettings.header': 'Contraseña y configuración',
  'profile.pages.profileInformation.header': 'Mi perfil',
  'profile.pages.profileInformation.previewButton': 'Obtenga una vista previa de mi perfil',
  'profile.pages.profileInformation.myAccount': 'Mi cuenta',
  'profile.pages.profileInformation.switchProfile': 'Cambiar de perfil',
  'profile.pages.team.header': 'Equipo',
  'profile.pageTitle': 'Mi cuenta',
  'profile.profile_avatar.recommended_size': 'Recomendado 250px. Tamaño máximo de archivo 5Mb.',
  'profile.profile_avatar.title': 'Imagen de perfil',
  'profile.profile_avatar.upload_photo': 'Cargar imagen',
  'profile.profile_information.about_me': 'Sobre mí',
  'profile.profile_information.about_me_placeholder': 'Date a conocer y explica por qué estas en el evento.',
  'profile.profile_information.job_title': 'Cargo',
  'profile.profile_information.title': 'Información de perfil',
  'profile.reset_password.sent': 'El correo electrónico para reiniciar su contraseña llegará pronto',
  'profile.reset_password.title': 'Reiniciar contraseña',
  'profile.rows.avatarModalTitle': 'Subir foto de perfil',
  'profile.rows.bio.bioPlaceholder': 'Mi trabajo implica … ',
  'profile.rows.companyLocations.addModalTitle': 'Añadir ubicación',
  'profile.rows.companyLocations.delete': '¿Confirma que desea eliminar esta ubicación?',
  'profile.rows.companyLocations.updateModalTitle': 'Edita la ubicación',
  'profile.rows.companyWebsites.removeWarning': '¿Estás seguro de que quieres eliminar este sitio web de tu perfil?',
  'profile.rows.country.selectCountryPlaceholder': '¿Dónde se encuentra?',
  'profile.rows.fullNameModalTitle': 'Edita nombre',
  'profile.rows.logo.logoModalTitle': 'Cargue el logotipo de la empresa',
  'profile.rows.lookingFor.inputLabel': '¿Qué tipo de contactos, empresas, productos o servicios le interesan?',
  'profile.rows.lookingFor.inputPlaceholder': 'Estoy buscando ...',
  'profile.rows.lookingFor.label': 'Estoy buscando ...',
  'profile.rows.lookingFor.modalTitle': 'Estoy buscando ...',
  'profile.rows.password.changeMessage': 'Enviar un enlace para cambiar la contraseña a:',
  'profile.rows.password.label': 'Contraseña actual',
  'profile.rows.password.modalTitle': 'Cambiar la contraseña',
  'profile.rows.password.passwordLinkSent': 'Enlace de contraseña enviado',
  'profile.rows.password.successMessage': '<h4> Email sent </h4><p> Hemos enviado un enlace para restablecer la contraseña a :<br/><a href="mailto:{{ email }}"> {{ email }} </a></p><p>El enlace caducará en una hora.</p>',
  'profile.rows.phone.deleteWarning': '¿Eliminar este número de teléfono de tu perfil?',
  'profile.rows.phone.info': 'Es possible que nos pongamos en contacto con used en relación con reunions o events importantes, actualizaciones for mensaje de Texto, o por teléfono. Tu número de teléfono no aparece en tu perfil público.',
  'profile.rows.socials.updateModalTitle': 'Edita sus sociales',
  'profile.rows.websites.addModalTitle': 'Añadir sitio web',
  'profile.rows.websites.updateModalTitle': 'Edita sitio web',
  'profile.tables.catalogueEntry.confirmDelete': '¿Estás seguro de que quieres eliminarlo? {{ name, lowercase }}?',
  'profile.tables.catalogueEntry.emptyMessage': 'No hay elementos que mostrar.',
  'profile.tables.imageGallery.addOne': 'Añadir',
  'profile.tables.imageGallery.confirmDelete': '¿Confirma que desea eliminar esta imagen?',
  'profile.tables.imageGallery.count_one': '({{ count }} el artículo)',
  'profile.tables.imageGallery.count_other': '({{ count }} artículos)',
  'profile.tables.imageGallery.emptyMessage': 'No has elementos que mostrar.',
  'profile.tables.imageGallery.title': 'Galería de imágenes',
  'profile.tables.videos.addOne': 'Añadir',
  'profile.tables.videos.confirmDelete': '¿Estás seguro de que quieres eliminar este vídeo?',
  'profile.tables.videos.count': '({{ count }} / {{ limit }})',
  'profile.tables.videos.emptyMessage': 'No hay elementos que mostrar.',
  'profile.tables.videos.title': 'Vídeos de perfil',
  'profile.tips.edit.body': 'La información utilizada para verificar su identidad y la empresa para la que trabaja no se pueden modificar. La información de contacto, preferencias y algunas particularidades se pueden editar, pero es possible que tengamos que pedirle que verifique su identidad antes de guardar actualizaciones.',
  'profile.tips.edit.title': '¿Qué información se puede editar?',
  'profile.tips.preview.title': 'Vista previa de la tarjeta de perfil',
  'profile.tips.share.body': 'Solo compartimos su información de contacto con socios de reunión confirmados.',
  'profile.tips.share.title': '¿Qué información se comparte con otros usuarios?',
  'profile.action.logout': 'Cerrar sesión',
  'profile_select.footer.contact_support': 'Ponerse en contacto con el equipo de apoyo',
  'profile_select.footer.multiple_profiles': '¿Por qué tengo múltiples perfiles?',
  'profile_select.footer.return_to_list': 'Volver a la lista de perfiles',
  'profile_select.header.info': 'Haga clic en las opciones del perfil para <b>conectar, emparejar o asignar</b> a un compañero de equipo.',
  'profile_select.header.subheader': 'Hay más de un perfil asociado con',
  'profile_select.header.title': 'Cambiar de perfil',
  'profile_select.info.duplicate_profiles.description': 'Se registró dos veces.',
  'profile_select.info.duplicate_profiles.solution_text': "Seleccione 'emparejar perfis' a través del menú de opciones del perfil y siga los pasos para combinar los datos en un solo perfil. Recomendamos que se conecte e verifique qual o perfil que deseja manter.",
  'profile_select.info.duplicate_profiles.title': 'Hay perfiles duplicados en mi nombre',
  'profile_select.info.i_see_colleagues.description': 'Su empresa puede haber registrado a varios colegas usando la misma dirección de correo electrónico ,por ejemplo: info@.',
  'profile_select.info.i_see_colleagues.solution_text': 'Seleccionar conexāo al colega a través del menú de opciones del perfil.',
  'profile_select.info.i_see_colleagues.title': 'Puedo ver los nombres de otros compañeros de mi empresa.',
  'profile_select.info.listed_different_company.description': 'Usted puede estar registrado o representar a diferentes empresas. Si esto es correcto, puede mantener e intercambiar los múltiples perfiles utilizando la página de selección de perfil para cambiar entre perfiles. Si esto es incorrecto:',
  'profile_select.info.listed_different_company.solution_text': "Seleccione 'eliminar'a través del menú de opciones del perfil. Este proceso no puede ser anulado, le recomendamos que se conecte y compruebe sus perfiles antes de borrarlos.",
  'profile_select.info.listed_different_company.title': 'Estoy listado con diferentes nombres de empresa.',
  'profile_select.info.solution': 'Resolución:',
  'profile_select.info.title': '¿Por qué tengo múltiples perfiles?',
  'profile_select.profile.last_used': 'Usado recientemente:',
  'profile_select.profile.never_logged_in': 'Nunca',
  'profile_select.primary_contact': 'Contacto primario',
  'ui.add': 'Añadir',
  'ui.delete': 'Eliminado',
  'ui.edit': 'Edita',
  'ui.faq': 'Preguntas frecuentes',
  'ui.help': 'Ayuda',
  'ui.language': 'Idioma',
  'ui.locked': 'Bloqueado',
  'ui.new': 'Nuevo',
  'ui.noItemsToDisplay': 'No hay elementos que mostrar.',
  'ui.notAdded': 'No se ha añadido',
  'ui.notSet': 'No está establecido',
  'ui.selectCountry': 'Selecciona país',
  'ui.support': 'Soporte',
  'ui.switchProfile': 'Cambie de perfil',
  'update_availability.buttons.cancel': 'Cancelar',
  'update_availability.buttons.update': 'Actualiza tu disponibilidad',
  'update_availability.header': 'Su disponibilidad',
  'update_availability.key.available': 'Disponible',
  'update_availability.key.unavailable': 'No disponible',
  'update_availability.subheader': 'Definir qué días y horas participarás en el evento.',
  'video_meeting.message_concierge.box_closing': 'Esta ventana se cerrará automáticamente en 5 segundos.',
  'video_meeting.message_concierge.buttons.cancel': 'Cancelar',
  'video_meeting.message_concierge.buttons.message_concierge': 'Enviar un mensaje al concierge',
  'video_meeting.message_concierge.buttons.send': 'Enviar',
  'video_meeting.message_concierge.connecting': 'conectando',
  'video_meeting.message_concierge.header': 'Pedir ayuda al concierge',
  'video_meeting.message_concierge.last_seen': '(Activo hace {{ last_seen }})',
  'video_meeting.message_concierge.message_sent': 'Su mensaje fue enviado al equipo de conserjería, ver la página de chat para obtener una respuesta.',
  'video_meeting.message_concierge.online': 'online',
  'video_meeting.message_concierge.waiting_join': 'Esperando usuário',
  chat_visible: 'Chat visible para todos los participantes',

  'meeting_invite.notification.confirmed_meeting': 'Tienes una <a href="{{hash}}">reunión confirmada</a> con este contacto.',
  'meeting_invite.notification.invite_already_sent': '<a href="{{hash}}">Invitación ya enviada</a> a este contacto.',
  'meeting_invite.notification.invite_already_received': 'Tienes una <a href="{{hash}}">nueva invitación a una reunión</a> de este contacto.',
  'meeting_invite.notification.invite_withdrew_by_ac': 'Invitación retirada el {{withdrawn_time}}. <a href="{{hash}}">Ver detalles</a>.',
  'meeting_invite.notification.meeting_cancelled_by': 'Se canceló una reunión anterior.<a href="{{hash}}">Ver reunión</a>.',
  'meeting_invite.notification.meeting_with_contact_colleague': 'Tienes una <a href="{{hash}}">reunión confirmada</a> con esta empresa.',
  'meeting_invite.notification.meeting_with_contact_declined': 'Su <a href="{{hash}}">invitación a la reunión</a> fue rechazada.',
  'meeting_invite.notification.meeting_with_contact_declined_by_you': 'Has rechazado una <a href="{{hash}}">invitación a reunión</a> de este contacto.',
  'meeting_invite.notification.colleague_meeting_intersect': 'Tu colega se está reuniendo con esta empresa.',
  'meeting_invite.notification.meeting_with_colleague_confirmed': 'Tu colega está reuniéndose con este contacto.',
  'meeting.notification.view_meeting': 'Ver reunión',

  // Registrations
  'registrations.form.questions.component.name.first_name': 'antaŭnomo',
  'registrations.form.questions.component.name.last_name': 'Apellido',
  'registrations.form.questions.component.name.first_name_placeholder': 'Enter first nameIntroduce el nombre',
  'registrations.form.questions.component.name.last_name_placeholder': 'Introduzca el apellido',
  'registrations.form.actions.submit': 'Entregar',
  'registrations.form.actions.next': 'Próximo',
  'registrations.form.screens.thankyou.heading': '<strong>Congratulations {{ name }}</strong>, your {{ eventName }} registration is complete.',
  'registrations.form.screens.thankyou.sub_heading': 'We’ve send you a confirmation email.',
  'registrations.form.screens.not_active.description': 'Este formulario de registro no está disponible actualmente, inténtelo nuevamente más tarde.',
  'registrations.form.screens.closed.alert_message': 'La inscripción ha cerrado.',
  'registrations.form.screens.coming_soon.alert_message': 'La inscripción se abre pronto.',
  'registrations.form.actions.send_login_link': 'Enviar un enlace de inicio de sesión',
  'registrations.form.actions.change_email_address': 'Cambiar dirección de correo electrónico',
  'registrations.form.actions.send_link_to_continue': 'Enviar un enlace para continuar',
  'registrations.form.validation.email_already_registered_message': 'Tu dirección de correo electrónico ya está registrada...',
  'registrations.form.validation.email_already_have_partial_registration_message': 'Su dirección de correo electrónico tiene un registro parcial...',
  'registrations.form.buttons.login': 'Log in',
  'registrations.form.actions.resend_email': 'Resend email',
  'registrations.form.actions.resend_email.message': 'Email sent!',

  // Videos
  'video_card.more': 'Más detalles',
  'video_card.view_detail': 'Detalle del video',
  'videoModal.buttons.view_company': 'Ver empresa',

  // Task
  'hubs.tasks.link': 'Tareas',
  'hubs.tasks.title': 'Tareas',
  'hubs.tasks.taskProgress': 'Progreso de la tarea',
  'hubs.tasks.pendingTasksTitle': 'Tareas pendientes',
  'hubs.tasks.pendingCompanyTasksDescription': 'Las siguientes tareas deben ser completadas por un representante de su empresa.',
  'hubs.tasks.pendingContactTasksDescription': 'Las siguientes tareas deben ser completadas por usted.',
  'hubs.tasks.noPendingTasks': 'No hay tareas por completar.',
  'hubs.tasks.taskType.mandatory': 'Requerido',
  'hubs.tasks.taskType.important': 'Importante',
  'hubs.tasks.taskType.optional': 'Opcional',
  'hubs.tasks.completedTasksTitle': 'Tareas completadas',
  'hubs.tasks.completedTasksDescription': 'Las siguientes tareas han sido completadas.',
  'hubs.tasks.nocompletedTasks': 'No tienes tareas completadas.',
  'hubs.tasks.start': 'Iniciar',
  'hubs.tasks.expired': 'Expirado',
  'hubs.tasks.view': 'Ver',
  'hubs.tasks.modal.title': 'Detalles de la tarea',
  'hubs.tasks.modal.cancel': 'Cancelar',
  'hubs.tasks.modal.markAsRead': 'Marcar como leído',
  'hubs.tasks.modal.submit': 'Enviar',
  'hubs.tasks.modal.close': 'Cerrar',
  'hubs.tasks.completed_by': 'Completado por {{ responder }} el {{ when }}',
  'hubs.tasks.file_submitted': 'Ya has subido un archivo',
  'hubs.tasks.file_download': 'Descargar archivo',
  'hubs.tasks.user_file_download': 'Descargar archivo',
  'hubs.tasks.capacity_remaining': '{{ capacity_remaining }} restantes',
  'hubs.tasks.capacity_remaining_zero': 'Agotado',
  'hubs.tasks.deadline': 'Fecha límite',
  'hubs.tasks.completed': 'Completado',

  'floorplan.open_location_on_map': 'Abrir mapa',

};

<template>
  <div class="floorplan">
    <div
      style="position: absolute; inset: 0"
      ref="container"
      :class="{ 'cursor-not-allowed': lockSelected }"
    >
      <svg
        v-if="floorplan"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        :viewBox="`${viewBox[0]} ${viewBox[1]} ${viewBox[2]} ${viewBox[3]}`"
        ref="svg"
      >
        <image
          x="0"
          y="0"
          :width="floorplan.width"
          :href="floorplan.background_url"
        />

        <FloorplanGrid
          v-if="showGrid"
          :floorplanWidth="floorplan.width"
          :floorplanDepth="floorplan.depth"
          :roundingFactor="floorplan.rounding_factor"
        />

        <FeFloorplanStand
          v-show="location.id !== selectedLocation?.id"
          v-for="location in floorplan.locations"
          :key="location.id"
          :location="location"
          @mouseup="isPanning ? null : locationClicked(location)"
        />
        <FeFloorplanStand
          :key="selectedLocation.id"
          v-if="selectedLocation"
          ref="selectedLocationEl"
          :location="selectedLocation"
          fillColour="#B0E0A8"
          :draggable="lockSelected"
          :isDragging="lockSelected && isDragging"
          :className="flashSelectedLocation ? 'fe-flash-selected' : ''"
          :textClassName="flashSelectedLocation ? 'fe-selected-text' : ''"
        />
        <g v-if="lockSelected && selectedLocation">
          <rect
            :x="selectedLocation.x + (selectedLocation.width / 2) - 50"
            :y="selectedLocation.y - 50"
            :width="100"
            :height="100"
            fill="#ffffff"
            stroke="#000000"
            stroke-width="5"
            ref="topHandle"
            class="cursor-row-resize"
          />
          <rect
            :x="selectedLocation.x + selectedLocation.width - 50"
            :y="selectedLocation.y + (selectedLocation.depth / 2) - 50"
            :width="100"
            :height="100"
            fill="#ffffff"
            stroke="#000000"
            stroke-width="5"
            ref="rightHandle"
            class="cursor-col-resize"
          />
          <rect
            :x="selectedLocation.x + (selectedLocation.width / 2) - 50"
            :y="selectedLocation.y + selectedLocation.depth - 50"
            :width="100"
            :height="100"
            fill="#ffffff"
            stroke="#000000"
            stroke-width="5"
            ref="bottomHandle"
            class="cursor-row-resize"
          />
          <rect
            :x="selectedLocation.x - 50"
            :y="selectedLocation.y + (selectedLocation.depth / 2) - 50"
            :width="100"
            :height="100"
            fill="#ffffff"
            stroke="#000000"
            stroke-width="5"
            ref="leftHandle"
            class="cursor-col-resize"
          />
        </g>
        <template v-if="showPaths">
          <FloorplanPath
            v-for="path in floorplan.paths"
            :key="path.id"
            :path="path"
            :overridePathColor="overridePathColor"
            @pathClicked="pathClicked"
          />

          <FloorplanPath
            v-if="selectedPath"
            ref="selectedPathEl"
            :key="selectedPath.id"
            :path="selectedPath"
            overridePathColor="#000000"
          />

          <g v-if="lockSelected && selectedPath">
            <circle
              :cx="selectedPath.x1"
              :cy="selectedPath.y1"
              r="48"
              fill="#ffffff"
              stroke="#000000"
              stroke-width="5"
              ref="pathHandle1"
              class="cursor-move"
            />
            <circle
              :cx="selectedPath.x2"
              :cy="selectedPath.y2"
              r="48"
              fill="#ffffff"
              stroke="#000000"
              stroke-width="5"
              ref="pathHandle2"
              class="cursor-move"
            />
          </g>

        </template>
        <FloorplanRoute
          v-if="activePath?.length"
          :activePath="activePath"
        />
      </svg>
      <div class="floorplan__coords bg-rock-200/50" v-if="showCoords && (svgX !== null && svgY !== null)">
        <i class="fas fa-compass me-1"></i>
        {{ (svgX / floorplan.rounding_factor).toFixed(2) }},
        {{ (svgY / floorplan.rounding_factor).toFixed(2) }}
      </div>
    </div>

  </div>

</template>

<script setup>
import {
  ref, watch, toRefs, onMounted, nextTick,
} from 'vue';
import { useEventListener, useElementSize } from '@vueuse/core';
import FeFloorplanStand from './FeFloorplanStand.vue';
import FloorplanGrid from './FloorplanGrid.vue';
import FloorplanPath from './FloorplanPath.vue';
import FloorplanRoute from './FloorplanRoute.vue';

const props = defineProps({
  floorplan: Object,
  showGrid: Boolean,
  showPaths: Boolean,
  showCoords: Boolean,
  width: Number,
  height: Number,
  lockSelected: Boolean,
  activePath: {
    type: Array,
    default: () => [],
  },
  selectedLocation: Object,
  flashSelectedLocation: Boolean,
  overridePathColor: String,
  selectedPath: Object,
});

const emit = defineEmits([
  'svgReady',
  'svgClicked',
  'contentMenuOpened',
  'hover',
  'locationClicked',
  'locationDblClicked',
  'locationMove',
  'locationMoved',
  'locationResize',
  'locationResized',
  'pathClicked',
  'pathDblClicked',
  'pathNodeMove',
  'pathNodeMoved',
]);

const { floorplan } = toRefs(props);

const container = ref(null);

const { width: containerWidth, height: containerHeight } = useElementSize(container);

const viewBox = ref([0, 0, 0, 0]);

function transitionViewBox(
  targetX,
  targetY,
  targetWidth,
  targetHeight,
  duration = 1000,
) {
  const startX = viewBox.value[0];
  const startY = viewBox.value[1];
  const startWidth = viewBox.value[2];
  const startHeight = viewBox.value[3];

  const startTime = performance.now();

  function animate(time) {
    let progress = (time - startTime) / duration;

    progress = Math.min(1, progress); // Cap at 1

    // Simple easing function for a smooth effect (ease-in-out)
    const easeFactor = 0.5 - Math.cos(progress * Math.PI) / 2;

    // Interpolate each viewBox parameter
    viewBox.value = [
      startX + (targetX - startX) * easeFactor,
      startY + (targetY - startY) * easeFactor,
      startWidth + (targetWidth - startWidth) * easeFactor,
      startHeight + (targetHeight - startHeight) * easeFactor,
    ];

    if (progress < 1) {
      requestAnimationFrame(animate);
    }
  }

  // Start the animation
  requestAnimationFrame(animate);
}

function resetViewBox(animate = true) {
  const multiplier = (containerWidth.value / containerHeight.value) * (floorplan.value.depth / floorplan.value.width);
  const viewportHeight = (1.1 * floorplan.value.depth) / Math.min(1, multiplier);
  const viewportWidth = 1.1 * floorplan.value.width * Math.max(1, multiplier);
  const startX = (viewportWidth - floorplan.value.width) / 2;
  const startY = (viewportHeight - floorplan.value.depth) / 2;

  if (animate) {
    transitionViewBox(-1 * startX, -1 * startY, viewportWidth, viewportHeight);
  } else {
    viewBox.value = [
      -1 * startX,
      -1 * startY,
      viewportWidth,
      viewportHeight,
    ];
  }
}

onMounted(() => {
  resetViewBox(false);
  emit('svgReady');
});

let initW = false;
let initH = false;

watch(containerWidth, (newVal, oldVal) => {
  if (!initW) {
    initW = true;
  } else {
    viewBox.value[2] *= Math.max(newVal, 1) / Math.max(oldVal, 1);
  }
});

watch(containerHeight, (newVal, oldVal) => {
  if (!initH) {
    initH = true;
  } else {
    viewBox.value[3] *= Math.max(newVal, 1) / Math.max(oldVal, 1);
  }
});

const svg = ref(null);

const selectedLocationEl = ref(null);
const topHandle = ref(null);
const rightHandle = ref(null);
const bottomHandle = ref(null);
const leftHandle = ref(null);
const selectedPathEl = ref(null);
const pathHandle1 = ref(null);
const pathHandle2 = ref(null);

let isPanning = false;
let isDragging = false;
let isResizing = false;
let isPathNodeMoving = false;

let touchTimeout; // Handle context menu for touch devices
let isLongPress = false; // Handle context menu for touch devices
let initialTouchX = 0;
let initialTouchY = 0;
let lastTapTime = 0; // Handle double for touch devices

function locationClicked(location) {
  if (props.lockSelected) {
    return;
  }

  if (props.selectedLocation?.id === location.id) {
    return;
  }

  emit('locationClicked', location);
}

function pathClicked(path) {
  if (props.lockSelected) {
    return;
  }

  if (props.selectedPath?.id === path.id) {
    return;
  }

  emit('pathClicked', path);
}

function moveSelected(movementX, movementY) {
  const ctm = svg.value.getScreenCTM();

  const movement = new DOMMatrix([0, 0, movementX, movementY, 0, 0]).multiply(ctm.inverse());

  if (isDragging) {
    const newX = props.selectedLocation.x + movement.c - movement.a;
    const newY = props.selectedLocation.y + movement.d - movement.b;

    if (newX !== props.selectedLocation.x || newY !== props.selectedLocation.y) {
      emit('locationMove', {
        x: newX,
        y: newY,
      });
    }
  }
}

function moveSelectedPathNode(movementX, movementY) {
  const ctm = svg.value.getScreenCTM();

  const movement = new DOMMatrix([0, 0, movementX, movementY, 0, 0]).multiply(ctm.inverse());

  if (isPathNodeMoving) {
    const x = isPathNodeMoving === 'first' ? props.selectedPath.x1 : props.selectedPath.x2;
    const y = isPathNodeMoving === 'first' ? props.selectedPath.y1 : props.selectedPath.y2;

    const newX = x + movement.c - movement.a;
    const newY = y + movement.d - movement.b;

    if (newX !== x || newY !== y) {
      emit('pathNodeMove', {
        node: isPathNodeMoving,
        x: newX,
        y: newY,
      });
    }
  }
}

function zoomToLocation(location) {
  const multiplier = (containerWidth.value / containerHeight.value) * (floorplan.value.depth / floorplan.value.width);

  if (!Number.isFinite(multiplier) || multiplier === 0) {
    return;
  }

  const newWidth = containerWidth.value / 0.2;
  const newHeight = newWidth / multiplier;

  transitionViewBox(
    location.centre_x - (newWidth / 2),
    location.centre_y - (newHeight / 2),
    newWidth,
    newHeight,
  );
}

function screenToSvg(x, y) {
  const ctm = svg.value.getScreenCTM();

  return DOMPointReadOnly.fromPoint({ x, y })
    .matrixTransform(ctm.inverse());
}

function zoomToPoint(scaleFactor, x, y) {
  const svgPoint = screenToSvg(x, y);

  viewBox.value = [
    (viewBox.value[0] - svgPoint.x) * scaleFactor + svgPoint.x,
    (viewBox.value[1] - svgPoint.y) * scaleFactor + svgPoint.y,
    Math.max(viewBox.value[2] * scaleFactor, 1),
    Math.max(viewBox.value[3] * scaleFactor, 1),
  ];
}

function zoom(zoomFactor) {
  const newWidth = viewBox.value[2] * zoomFactor;
  const newHeight = viewBox.value[3] * zoomFactor;

  const newX = viewBox.value[0] + ((viewBox.value[2] - newWidth) / 2);
  const newY = viewBox.value[1] + ((viewBox.value[3] - newHeight) / 2);

  transitionViewBox(newX, newY, newWidth, newHeight, 500);
}

function pan(x, y) {
  const ctm = svg.value.getScreenCTM();

  const panned = new DOMMatrix([0, 0, x, y, 0, 0]).multiply(ctm.inverse());

  viewBox.value[0] += panned.c - panned.a;
  viewBox.value[1] += panned.d - panned.b;
}

function resize(movementX, movementY, emitEvent) {
  const ctm = svg.value.getScreenCTM();
  const movement = new DOMMatrix([0, 0, movementX, movementY, 0, 0]).multiply(ctm.inverse());

  const newDimensions = {
    x: props.selectedLocation.x,
    y: props.selectedLocation.y,
    width: props.selectedLocation.width,
    depth: props.selectedLocation.depth,
  };

  if (isResizing === 'up') {
    const y = props.selectedLocation.y - (movement.b - movement.d);
    const depth = props.selectedLocation.depth + (movement.b - movement.d);

    if (depth > 1) {
      newDimensions.y = y;
      newDimensions.depth = depth;
      emit(emitEvent, newDimensions);
    }
    return;
  }

  if (isResizing === 'right') {
    const width = props.selectedLocation.width + movement.c - movement.a;

    if (width > 1) {
      newDimensions.width = width;
      emit(emitEvent, newDimensions);
    }
    return;
  }

  if (isResizing === 'down') {
    const depth = props.selectedLocation.depth + movement.d - movement.b;

    if (depth > 1) {
      newDimensions.depth = depth;
      emit(emitEvent, newDimensions);
    }
    return;
  }

  if (isResizing === 'left') {
    const x = props.selectedLocation.x - (movement.a - movement.c);
    const width = props.selectedLocation.width + (movement.a - movement.c);

    if (width > 1) {
      newDimensions.x = x;
      newDimensions.width = width;
      emit(emitEvent, newDimensions);
    }
  }
}

function handleMove(deltaX, deltaY) {
  if (isResizing) {
    resize(deltaX, deltaY, 'locationResize'); // Resize uses the raw deltas
    return;
  }

  if (!isDragging && !isPathNodeMoving) {
    isPanning = true;
    pan(-deltaX, -deltaY); // Pan requires inverted deltas
    return;
  }

  if (isDragging && props.lockSelected && props.selectedLocation) {
    moveSelected(deltaX, deltaY); // MoveSelected uses the raw deltas
    return;
  }

  if (isPathNodeMoving && props.lockSelected && props.selectedPath) {
    moveSelectedPathNode(deltaX, deltaY); // MoveSelectedPathNode uses the raw deltas
  }
}

function handleEnd(event, deltaX = 0, deltaY = 0) {
  if (isResizing) {
    event.preventDefault();
    resize(deltaX, deltaY, 'locationResized'); // Resize finalises with deltas
    isResizing = false;
    return;
  }

  if (isDragging) {
    emit('locationMoved', {
      x: props.selectedLocation.x,
      y: props.selectedLocation.y,
    });
    isDragging = false;
    return;
  }

  if (isPathNodeMoving) {
    emit('pathNodeMoved');
    isPathNodeMoving = false;
    return;
  }

  isPanning = false;
}

useEventListener(selectedLocationEl, 'dblclick', () => {
  if (!props.lockSelected) {
    emit('locationDblClicked', props.selectedLocation);
  }
});

useEventListener(selectedLocationEl, 'touchend', () => {
  const currentTime = new Date().getTime();
  const timeDifference = currentTime - lastTapTime;

  // If the time between taps is less than 300ms, it's considered a double tap
  if (timeDifference < 300 && timeDifference > 0) {
    if (!props.lockSelected) {
      emit('locationDblClicked', props.selectedLocation);
    }
  }

  lastTapTime = currentTime;
});

useEventListener(selectedPathEl, 'dblclick', () => {
  if (!props.lockSelected) {
    emit('pathDblClicked', props.selectedPath);
  }
});

useEventListener(selectedPathEl, 'touchend', () => {
  const currentTime = new Date().getTime();
  const timeDifference = currentTime - lastTapTime;

  // If the time between taps is less than 300ms, it's considered a double tap
  if (timeDifference < 300 && timeDifference > 0) {
    if (!props.lockSelected) {
      emit('pathDblClicked', props.selectedPath);
    }
  }

  lastTapTime = currentTime;
});

useEventListener(svg, 'wheel', (event) => {
  zoomToPoint(1 + 0.01 * event.deltaY, event.clientX, event.clientY);
  event.preventDefault();
});

useEventListener(topHandle, 'pointerdown', () => {
  isResizing = 'up';
});

useEventListener(rightHandle, 'pointerdown', () => {
  isResizing = 'right';
});

useEventListener(bottomHandle, 'pointerdown', () => {
  isResizing = 'down';
});

useEventListener(leftHandle, 'pointerdown', () => {
  isResizing = 'left';
});

useEventListener(pathHandle1, 'pointerdown', () => {
  isPathNodeMoving = 'first';
});

useEventListener(pathHandle2, 'pointerdown', () => {
  isPathNodeMoving = 'second';
});

useEventListener(selectedLocationEl, 'pointerdown', () => {
  if (props.lockSelected) {
    isDragging = true;
  }
});

useEventListener(svg, 'mousedown', () => {
  emit('svgClicked');
});

useEventListener(svg, 'mousemove', (event) => {
  event.preventDefault();

  if (event.buttons !== 0) {
    handleMove(event.movementX, event.movementY); // Mouse deltas are passed directly
  }
});

useEventListener(window, 'mouseup', (event) => {
  handleEnd(event, event.movementX, event.movementY);
});

useEventListener(svg, 'touchstart', (event) => {
  if (event.touches.length === 1) {
    // Clear any previous timeouts
    clearTimeout(touchTimeout);
    isLongPress = false;

    // Set a timeout to detect a long press
    touchTimeout = setTimeout(() => {
      isLongPress = true;
    }, 500); // 500ms delay for long press

    initialTouchX = event.touches[0].clientX;
    initialTouchY = event.touches[0].clientY;
  }
});

useEventListener(svg, 'touchmove', (event) => {
  event.preventDefault();

  if (event.touches.length === 1) {
    const touch = event.touches[0];

    const deltaX = initialTouchX - touch.clientX; // Calculate deltas for touch
    const deltaY = initialTouchY - touch.clientY;

    initialTouchX = touch.clientX; // Update initial touch positions
    initialTouchY = touch.clientY;

    handleMove(-deltaX, -deltaY); // Pass inverted deltas for consistency with `mousemove`
  }
});

useEventListener(svg, 'touchend', (event) => {
  clearTimeout(touchTimeout);

  if (!isLongPress) {
    emit('svgClicked');
  }

  handleEnd(event); // No deltas for touchend
});

useEventListener(svg, 'contextmenu', (event) => {
  event.preventDefault();
  const { x, y } = (screenToSvg(event.clientX, event.clientY));
  emit('contentMenuOpened', {
    x,
    y,
    offsetX: event.offsetX,
    offsetY: event.offsetY,
  });
});

const svgX = ref(null);
const svgY = ref(null);

if (props.showCoords) {
  useEventListener(svg, 'mousemove', ({ clientX, clientY }) => {
    const { x, y } = screenToSvg(clientX, clientY);
    svgX.value = x;
    svgY.value = y;
  });
}

async function download() {
  await nextTick();

  const blob = new Blob(
    [svg.value.outerHTML],
    { type: 'text/svg' },
  );
  const filename = 'floorplan.svg';

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}

defineExpose({
  download,
  zoomToLocation,
  resetViewBox,
  zoom,
});
</script>

<style>

.floorplan {
  position: relative;
  background-color: #F8FAFC;
  width:100%;
  height: 100%;
  max-width:100%;
}

.floorplan__coords {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 1rem;
  padding: 0.5rem;
  min-width: 10rem;
  text-align: center;
  font-size: 12px;
  z-index: 100;
  color: rgb(15 23 42 / 1);
  background-color: #fff;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  border-radius: 0.25rem;
}

.fe-flash-selected {
  fill: var(--brand-primary-color);

  /* @keyframes duration | easing-function | delay | iteration-count | direction | fill-mode | play-state | name */
  animation: feFlash 0.8s ease-in 2s infinite alternate-reverse;
}

.fe-selected-text {
  fill: var(--system-white);
}

@keyframes feFlash {
  to {
    fill: var(--brand-primary-color-dark);
  }
}

</style>

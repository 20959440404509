<template>
    <div
      class="floorplan-page"
      :class="{
        'floorplan-page--fullscreen': fullScreenMode,
      }"
    >
      <div class="floorplan-page__container">
        <div class="floorplan-page__locations">
          <div class="floorplan-page__search">
            <FrontendTextInput
              id="locationSearch"
              key="locationSearch"
              name="locationSearch"
              v-model="locationSearch"
              :max="500"
              hideCounter
              placeholderIcon="far fa-search"
              placeholder="Find"
            />
          </div>
          <ul class="floorplan-page__location-list">
            <li
              v-for="location in filteredLocations"
              :key="location.id"
              :id="`locationList_${location.id}`"
              class="floorplan-page__location-list-item"
              :class="{
                'floorplan-page__location-list-item--active': location.id === selectedLocation?.id,
              }"
              @click="selectLocation(location)"
            >
              <span v-if="location.name !== 'Available' && location.name !== 'Reserved'">
                {{ location.name }}
              </span>
              <span v-if="location.number">
                {{ location.number }}
              </span>

              <a
                v-if="location.routes.preview"
                v-show="location.id === selectedLocation?.id"
                :href="location.routes.preview"
                @click.stop
                style="margin-left: auto; color: white"
              >
                <i class="far fa-up-right-from-square"></i>
              </a>
            </li>
          </ul>
        </div>

        <div
          class="floorplan-page__floorplan"
          style="position: relative;"
        >
          <FloorplanSVG
            v-if="floorplan?.id"
            ref="floorplanSVG"
            :floorplan="floorplan"
            :selectedLocation="selectedLocation"
            :lockSelected="false"
            :isEditingLocation="false"
            @locationClicked="(location) => locationClicked(location)"
            @locationDblClicked="previewLocation"
            flashSelectedLocation
            :showPaths="true"
            overridePathColor="#EAEFF4"
            :activePath="activePath"
            @svgReady="zoomSoon"
          />

          <div class="floorplan-page__control-icons">
            <a class="floorplan-page__control-icon" href="#" @click.prevent="plotPathFromEntrance">
              <i class="far fa-truck"></i>
            </a>
            <a class="floorplan-page__control-icon" href="#" @click.prevent="floorplanSVG?.zoom(3/4)">
              <i class="far fa-plus"></i>
            </a>
            <a class="floorplan-page__control-icon" href="#" @click.prevent="floorplanSVG?.zoom(4/3)">
              <i class="far fa-minus"></i>
            </a>
            <a class="floorplan-page__control-icon" href="#" @click.prevent="floorplanSVG?.resetViewBox">
              <i class="far fa-expand-wide"></i>
            </a>
            <a class="floorplan-page__control-icon" href="#" @click.prevent="fullScreenMode = !fullScreenMode">
              <i class="far fa-arrows-maximize"></i>
            </a>
          </div>
        </div>

      </div>
    </div>
</template>

<script setup>

import { ref, computed } from 'vue';
import axios from 'axios';
import { store } from '~/Frontend/store';

const props = defineProps({
  floorplanId: String,
  locationId: String,
});

const selectedLocation = ref(null);
const activePath = ref(null);

const locationSearch = ref('');

const floorplanSVG = ref(null);

const floorplan = ref(null);

const aspectRatio = ref('16 / 9');

const fullScreenMode = ref(false);

axios.get(`/${store.eventEdition.slug}/floorplan/${props.floorplanId}`)
  .then((response) => {
    floorplan.value = response.data.floorplan;
    aspectRatio.value = `${floorplan.value.width} / ${floorplan.value.depth}`;
  });

function selectLocation(location) {
  floorplanSVG.value.zoomToLocation(location);
  selectedLocation.value = location;
}

function locationClicked(location) {
  selectLocation(location);

  const locElement = document.getElementById(`locationList_${location.id}`);
  locElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
}

function previewLocation() {
  if (selectedLocation.value?.routes.preview) {
    window.location.hash = selectedLocation.value?.routes.preview;
  }
}

function zoomSoon() {
  if (props.locationId) {
    setTimeout(() => {
      const location = floorplan.value.locations.find((loc) => loc.id.toString() === props.locationId.toString());
      floorplanSVG.value.zoomToLocation(location);
      selectedLocation.value = location;
    }, 1000);
  }
}

const filteredLocations = computed(() => {
  if (!floorplan.value?.locations) {
    return [];
  }

  if (locationSearch.value.length > 1) {
    const search = locationSearch.value.trim().toLowerCase();
    return floorplan.value.locations.filter((item) => item.name.toLowerCase().includes(search) || item.number.toLowerCase().includes(search));
  }
  return floorplan.value.locations;
});

function manhattenDistance(a, b) {
  return Math.abs(a.x - b.x) + Math.abs(a.y - b.y);
}

function euclideanDistance(a, b) {
  return Math.sqrt((b.x - a.x) ** 2 + (b.y - a.y) ** 2);
}

function key(point) {
  return `${point.x}_${point.y}`;
}

function dekey(k) {
  return { x: +k.split('_')[0], y: +k.split('_')[1] };
}

function reconstructPath(cameFrom, current) {
  let node = key(current);
  const path = [];

  do {
    path.push(dekey(node));
    node = cameFrom.get(node);
  } while (node);

  return path;
}

// eslint-disable-next-line no-unused-vars
function aStar(graph, start, goal, metric = manhattenDistance) {
  const nodes = graph.map((node) => [{ x: node.x1, y: node.y1 }, { x: node.x2, y: node.y2 }]).flat();

  // Find the closest node to start
  const startNode = nodes.reduce(
    (acc, val) => ((!acc || euclideanDistance(start, val) < euclideanDistance(acc, start)) ? val : acc),
    null,
  );

  // Find the closest node to goal
  const goalNode = nodes.reduce(
    (acc, val) => ((!acc || euclideanDistance(goal, val) < euclideanDistance(acc, goal)) ? val : acc),
    null,
  );

  const queue = new Set([key(startNode)]);
  const cameFrom = new Map();

  const gScore = new Map();
  nodes.forEach((node) => {
    gScore.set(key(node), Infinity);
  });

  const fScore = new Map();
  nodes.forEach((node) => {
    fScore.set(key(node), Infinity);
  });

  gScore.set(key(startNode), 0);
  fScore.set(key(startNode), metric(startNode, goalNode));

  let count = 0;

  while (queue.size > 0) {
    if (count > 40) return null;
    count += 1;
    let current = null;
    let minFScore = Infinity;

    queue.forEach((node) => {
      if (fScore.get(node) < minFScore) {
        current = dekey(node);
        minFScore = fScore.get(node);
      }
    });

    if (current.x === goalNode.x && current.y === goalNode.y) {
      return reconstructPath(cameFrom, current);
    }

    queue.delete(key(current));

    graph.forEach((path) => {
      let neighbor;

      if (path.x1 === current.x && path.y1 === current.y) {
        neighbor = { x: path.x2, y: path.y2 };
      } else if (path.x2 === current.x && path.y2 === current.y) {
        neighbor = { x: path.x1, y: path.y1 };
      } else {
        return;
      }

      const distance = euclideanDistance(current, neighbor);

      const tentativeGScore = gScore.get(key(current)) + distance;

      if (tentativeGScore < gScore.get(key(neighbor))) {
        cameFrom.set(key(neighbor), key(current));
        gScore.set(key(neighbor), tentativeGScore);
        fScore.set(key(neighbor), tentativeGScore + metric(neighbor, goalNode));
        if (!queue.has(key(neighbor))) {
          queue.add(key(neighbor));
        }
      }
    });
  }

  return null;
}

function plotPathFromEntrance() {
  if (!selectedLocation.value) {
    return;
  }

  // top right node on path
  const start = {
    x: 8100,
    y: 3500,
  };

  const goal = {
    x: selectedLocation.value.centre_x,
    y: selectedLocation.value.centre_y,
  };

  activePath.value = aStar(floorplan.value.paths, start, goal);
}

</script>

<script>

import storeManager from '../../Frontend/store';

export default {
  props: {
    data: Object,
  },
  render: () => '',
  created() {
    storeManager.set(this.data);
  },
};
</script>
